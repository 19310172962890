import React, { useEffect, useState } from 'react'
import {
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { search } from './search.actions'
import { makeStyles } from '@material-ui/core'
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons'
import { MyToast } from '../MyToast'
import { Spinner } from 'react-bootstrap'
import { itemsPerPage } from '../../utils/constant'
import { Pagination } from '../XomidhanAdmin/Views/Pagination'

const noOfLettersToShow = 200

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'white',
		padding: '1.5rem',
		gap: '1.5rem',
	},
	results: {
		gap: '1rem',
	},
	question: {
		gap: '0.3rem',
	},
	questionText: {
		color: '#0087ff',
		fontWeight: 'bold',
	},
	readMoreBtn: {
		color: '#0087ff',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'none',
		},
	},
}))

const Search = () => {
	const [searchBy, setSearchBy] = useState('')
	const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)
	const [openAnswer, setOpenAnswer] = useState(null)
	const { searchString } = useParams()
	const classes = useStyles()
	const history = useHistory()
	const [toast, setToast] = useState({
		message: '',
		visible: false,
		timeOut: 5000,
		type: 'success',
	})

	useEffect(() => {
		getResults()
		setSearchBy(searchString)
	}, [searchString])

	// function to dismiss toast
	const hideToast = () => {
		setToast({ ...toast, visible: false })
	}

	const getResults = () => {
		setLoading(true)
		search({ searchString: searchString }, (res, err) => {
			if (!err) {
				setResults(res)
				setTotalPages(Math.ceil(res.length / itemsPerPage))
			} else {
				setToast({
					...toast,
					visible: true,
					message: 'Something went Wrong!!! Try again...',
					type: 'failure',
				})
			}
			setLoading(false)
		})
	}

	const handleSearchChange = (e) => setSearchBy(e.target.value)

	const handleSearchOnEnter = (e) => {
		if (e.key === 'Enter' && !!searchBy) {
			handleSearch()
		}
	}

	const handleSearch = () => {
		if (!!searchBy) {
			history.push(`/search/query=${searchBy}`)
		}
	}

	return (
		<Grid container className={classes.root}>
			<Grid container>
				<Grid item xs={1} container justify='center'>
					<IconButton
						onClick={() => history.goBack()}
						aria-label='custom-search-bar-back-icon-button'
						edge='end'
					>
						<ArrowLeftOutlined style={{ fontSize: '18px' }} />
					</IconButton>
				</Grid>
				<Grid item xs={10}>
					<TextField
						variant='outlined'
						size='small'
						fullWidth
						placeholder='search questions'
						InputLabelProps={{ shrink: true }}
						value={searchBy}
						onChange={handleSearchChange}
						onKeyDown={handleSearchOnEnter}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position='end'
									style={{ gap: '0.75rem' }}
								>
									{!!searchBy ? (
										<IconButton
											onClick={() => setSearchBy('')}
											aria-label='custom-search-bar-search-icon-button'
											edge='end'
										>
											<CloseOutlined
												style={{ fontSize: '14px' }}
											/>
										</IconButton>
									) : null}
									<Button
										variant='text'
										style={{
											textTransform: 'none',
											color: '#0087ff',
										}}
										onClick={handleSearch}
									>
										Search
									</Button>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Divider />
			{loading ? (
				<Grid container justify='center' alignItems='center'>
					<Spinner animation='border' variant='primary' />
				</Grid>
			) : (
				<Grid container>
					<Grid container className={classes.results}>
						{results.length > 0 ? (
							results
								.slice(
									(currentPage - 1) * itemsPerPage,
									(currentPage - 1) * itemsPerPage +
										itemsPerPage
								)
								.map((item, index) => (
									<Grid
										container
										key={`search-items-${index}`}
										className={classes.question}
									>
										<Typography
											variant='h6'
											className={classes.questionText}
										>
											{`${
												index +
												1 +
												(currentPage - 1) * itemsPerPage
											}. ${item?.question || ''}`}
										</Typography>
										{item?.answer?.answer ? (
											<Typography variant='body1'>
												<strong>Answer: </strong>
												{item.answer.answer.slice(
													0,
													openAnswer === item.id
														? -1
														: noOfLettersToShow + 1
												)}
												{item.answer.answer.length >
												noOfLettersToShow ? (
													<Link
														className={
															classes.readMoreBtn
														}
														onClick={() =>
															setOpenAnswer(
																openAnswer ===
																	item.id
																	? ''
																	: item.id
															)
														}
													>
														<strong>
															{openAnswer ===
															item.id
																? '...read less'
																: '...read more'}
														</strong>
													</Link>
												) : null}
											</Typography>
										) : null}
									</Grid>
								))
						) : (
							<Grid container justify='center'>
								<Typography
									variant='h6'
									className={classes.questionText}
								>
									<i>"No results found..! :("</i>
								</Typography>
							</Grid>
						)}
					</Grid>
					{totalPages > 1 && (
						<Grid container justify='center'>
							<Pagination
								name={'search-results'}
								currentPage={currentPage}
								totalPages={totalPages}
								setCurrentPage={setCurrentPage}
							/>
						</Grid>
					)}
				</Grid>
			)}
			<MyToast
				message={toast.message}
				visible={toast.visible}
				type={toast.type}
				timeOut={toast.timeOut}
				hideToast={hideToast}
			/>
		</Grid>
	)
}

export default Search
