import React from "react";
import styles from "./quote.module.css";

const quotes = [
  {
    quote:
      "Questions open a space in your mind that allow better answers to breathe.",
    cite: "Richie Norton",
  },
  {
    quote:
      "We learn more by looking for the answer to a question and not finding it than we do from learning the answer itself.",
    cite: "Lloyed Alexander",
  },
  {
    quote:
      "By doubting we are led to question, by questioning we arrive at the truth",
    cite: "Peter Abelard",
  },
  {
    quote:
      "If you do not know how to ask the right question, you discover nothing.",
    cite: "W. Edwards Deming",
  },
  {
    quote:
      "We get wise by asking questions, and even if these are not answered, we get wise, for a well-packed question carries its answer on its back as a snail carries its shell.",
    cite: "James Stephens",
  },
  {
    quote:
      "Remember your dreams and fight for them. You must know what you want from life. There is just one thing that makes your dream become impossible: the fear of failure.",
    cite: "Paulo Coelho",
  },
];

function Quote() {
  const max = quotes.length;
  var i = Math.floor(Math.random() * Math.floor(max));
  return (
    <div className={styles.blockquote}>
      <p>{quotes[i].quote}</p>
      <cite>- {quotes[i].cite}</cite>
    </div>
  );
}
export default Quote;
