import { useState, useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import { Loading } from '../Loading'
import CounselorList from './CounselorList'
import * as counselorActions from './counselors.actions'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Pagination } from '../Pagination'
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { itemsPerPage } from '../../../../utils/constant'
import { CloseCircleOutlined } from '@ant-design/icons'

function Counselor() {
	const [counselors, setCounselors] = useState([])
	const [pageStatus, setPageStatus] = useState('pending')
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination
	const [searchBy, setSearchBy] = useState('')

	// global states
	const {
		isLoading,
		setIsLoading,
		setCurrentTab,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('counselor')
	}, [])

	useEffect(() => {
		getCounselors(true)
	}, [pageStatus, searchBy])

	useEffect(() => {
		getCounselors()
	}, [currentPage])

	const getCounselorCallback = (res, err) => {
		if (!err) {
			setTotalPages(res.page.totalPages)
			setCounselors([...res._embedded.counsellors])
			setIsLoading(false)
		} else {
		   setToast({
           				...toast,
           				visible: true,
           				message: 'Something went wrong',
           				type: 'failure',
           			})
		}
	}

	const getCounselors = (newCall = false) => {
		newCall && setCurrentPage(1)
		const params = {
			apiCallInterpreter,
			page: newCall ? 0 : currentPage - 1,
			size: itemsPerPage,
			status: pageStatus,
			name: searchBy,
		}
		setIsLoading(true)
		if (searchBy.length > 2) {
			counselorActions.getSearchedCounselors(params, getCounselorCallback)
		} else if(searchBy.length==0) {
			counselorActions.getCounselors(params, getCounselorCallback)
		}
	}

	const approveCounselor = (url) => {
		counselorActions.patchCounselors(
			{ url, data: { approved: true }, apiCallInterpreter },
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Counselor Approved',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				getCounselors()
			}
		)
	}

	const rejectCounselor = (url, reason) => {
		counselorActions.patchCounselors(
			{
				url,
				data: {
					approved: false,
					rejectionReason: reason,
					isCoreTeamMember: false,
				},
				apiCallInterpreter,
			},
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Counselor Rejected',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				getCounselors()
			}
		)
	}

	const removeCounselor = (url) => {
		counselorActions.removeCounselor(
			{ url, apiCallInterpreter },
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Counselor Removed',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				getCounselors()
			}
		)
	}

	const manageToCoreTeamMembers = (url, newStatus) => {
		counselorActions.patchCounselors(
			{
				url: `${url}/promote`,
				data: { isCoreTeamMember: newStatus },
				apiCallInterpreter,
			},
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Counselor Added to Core Team Members',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				getCounselors()
			}
		)
	}

	return (
		<>
			<Grid container className='titleBox' justify='space-between'>
				<h2>Counselors</h2>
				<Grid item xs={4}>
					<TextField
						variant='outlined'
						size='small'
						fullWidth
						label='search'
						placeholder='name'
						InputLabelProps={{ shrink: true }}
						value={searchBy}
						onChange={(e) => setSearchBy(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									{!!searchBy ? (
										<IconButton
											onClick={() => setSearchBy('')}
											aria-label='custom-search-bar-search-icon-button'
											edge='end'
										>
											<CloseCircleOutlined />
										</IconButton>
									) : null}
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>

			{isLoading ? (
				<Loading />
			) : (
				<Tabs
					activeKey={pageStatus}
					onSelect={(k) => setPageStatus(k)}
					className='mt-4'
				>
					{['pending', 'approved', 'rejected'].map((item, index) => (
						<Tab
							eventKey={item}
							title={item.charAt(0).toUpperCase() + item.slice(1)}
							key={index}
						>
							<CounselorList
								counselors={counselors}
								pageStatus={pageStatus}
								getCounselors={getCounselors}
								approveCounselor={approveCounselor}
								rejectCounselor={rejectCounselor}
								removeCounselor={removeCounselor}
								manageToCoreTeamMembers={
									manageToCoreTeamMembers
								}
							/>
							{totalPages > 1 && (
								<Pagination
									name={'Counsellors'}
									currentPage={currentPage}
									totalPages={totalPages}
									setCurrentPage={setCurrentPage}
								/>
							)}
						</Tab>
					))}
				</Tabs>
			)}
		</>
	)
}
export default Counselor
