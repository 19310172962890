import React from "react"
import { NavLink } from "react-router-dom"
import styles from "./about.module.css"

const items = [
	{
		key: "1",
		icon: (
			<i
				className="fas fa-angle-double-right"
				style={{ color: "#1687a7", padding: "25px", opacity: "0.5" }}
			></i>
		),
		title: "One step at a time",
		content:
			"We have come to a consensus to flag off Xomidhan with Career Counselling (please scroll down for details).",
	},
	{
		key: "2",
		icon: (
			<i
				className="fas fa-angle-double-right"
				style={{ color: "#1687a7", padding: "25px", opacity: "0.5" }}
			></i>
		),
		title: "You have a plan? Let's make it happen!",
		content:
			"While, we will focus on Careers as of now, we will remain open to proposals to join/initiate other ventures as long as it they are plausible (considering our geographical/time limitations).",
	},

	{
		key: "3",
		icon: (
			<i
				className="fas fa-angle-double-right"
				style={{ color: "#1687a7", padding: "25px", opacity: "0.5" }}
			></i>
		),
		title: "It isn't about a community",
		content:
			"When we refer to Assam or the Assamese, please remember it essentially includes all residents of that state and not the Assamese community in particular [as a matter of fact, the idea for our Org, was seeded by a Bengali resident of Nagaon]. So all are invited to send in your queries or join our panel of Career Counsellors (as applicable). Even non-residents of Assam, willing to extend a helping hand to our cause, are most welcome.",
	},
	{
		key: "4",
		icon: (
			<i
				className="fas fa-angle-double-right"
				style={{ color: "#1687a7", padding: "25px", opacity: "0.5" }}
			></i>
		),
		title: "Be one of us",
		content:
			"Do you have a good understanding of your career domain? good enough to provide professional guidance to aspirants? If yes, why don't you register yourself as a Career Counsellor with us? Only when Xomidhan receives a query related to your specific area of expertise, you will be required to respond. You can even don the role of a Mentor and guide a Mentee in his/her career. ",
		subContent: true,
	},
	{
		key: "5",
		icon: (
			<i
				className="fas fa-angle-double-right"
				style={{ color: "#1687a7", padding: "25px", opacity: "0.5" }}
			></i>
		),
		title: "Not brain-drain, but brain-chain",
		content:
			"Our network of Career Counsellors from different parts of the world, can provide authentic, even first-hand information, for students and careerists. But that should not be misinterpreted as promoting brain-drain. We only want to help people realize their true potential.",
	},
]

function About() {
	return (
		<>
			<div
				className="container-fluid bg-light"
				style={{ backgroundColor: "#ffffff" }}
			>
				<div
					className="titleBox"
					style={{ paddingTop: "40px", paddingLeft: "40px" }}
				>
					<h2>A cursory view of our charter</h2>
				</div>
				<div className="row">
					<div className="col-10 m-auto">
						{items.map((item, index) => {
							return (
								<div
									key={`about_item_${index}`}
									style={{ padding: "25px 0 0 0" }}
								>
									<span style={{ color: "#1687a7", fontSize: "18px" }}>
										{item.title}
									</span>
									<p
										style={{
											textAlign: "justify",
											fontWeight: "lighter",
											color: "#1d1d1f",
										}}
									>
										{item.content}
									</p>
									{item.subContent && (
										<p style={{ textAlign: "justify", color: "#1d1d1f" }}>
											If you do not want to become either of the two, you can
											still join us by simply being a part of our think tank or
											help us with your time or resources.{" "}
											<NavLink to="/join"> Click here</NavLink> to register.
										</p>
									)}
								</div>
							)
						})}
					</div>
				</div>
			</div>

			<div className={styles.lastParaContainer}>
				<div className={styles.lastParaOne}>
					<div className="row p-5">
						<div className="col-sm-12 col-lg-4" style={{ alignSelf: "center" }}>
							<h2 style={{ textOverflow: "clip", wordWrap: "break-word" }}>
								Career Counselling
							</h2>
						</div>
						<div
							className="col-lg-8 col-sm-12 pt-3"
							style={{
								borderLeft: "1px solid #fff",
							}}
						>
							<div className="pb-4">
								Confused regarding which course to opt for or which institute to
								head to ?
								<hr className="my-4" />
								Want to explore career options in various sectors in Assam or
								out of Assam ?
								<hr className="my-4" />
								Require a mentor in your field to guide you to become more
								successful in life ?
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-lg-10 m-auto p-sm-3 lastPara">
					<p>
						The above are only a few instances and every person has his/her
						unique issue. Share your career related problems with us, and we
						will give you the best solution we can. For example, if someone
						wants to crack the IIMs or the IITs, the most obvious suggestion he
						might get is that "Sit for CAT/JEE, perform well and that will be
						your ticket to those institutes". While that is not incorrect, what
						the aspirant also needs to be tipped about is "go back to primary
						school, pick up the Tables (Neota). Pick the art of mental
						calculations from the vendors in the market. Read and speak in
						English as much as possible (must for CAT exam)" and so on.
					</p>
					<h3>Who will Counsel ?</h3>
					<p>
						We have a team of counsellors empanelled with us-Subject Matter
						Experts from multidisciplinary streams like engineering, design,
						media, economics, public relations, medicine et al. They constitute
						professionals from prestigious organizations, successful
						entrepreneurs, academicians doing noteworthy research, to name a
						few.
					</p>
					<h3>We're just a form away...</h3>
					<p>
						If you want our help or guidance, here is a quick{" "}
						<NavLink className={styles.links} to="/ask">
							form
						</NavLink>{" "}
						for you. Please feel free to share the correct details, so that we
						can revert back (within five working days at the max) with a
						solution customised to suit your case.
					</p>
				</div>
			</div>
		</>
	)
}

export default About
