export const getQuestions = (params, callback) => {
	const request = {
		method: 'GET',
		url:
			params.type === 'AT'
				? `/questions?type=${params.type}&answered=false&page=${params.page}&size=${params.size}`
				: `/questions?type=nonAT&answered=false&page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const updateQuestion = (params, callback) => {
	const request = {
		method: params.method,
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const removeQuestion = (params, callback) => {
	const request = {
		method: 'DELETE',
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}

export const getCategories = (params, callback) => {
	const request = {
		method: 'GET',
		url: '/categories?size=1000',
	}
	params.apiCallInterpreter(request, callback)
}

export const submitAnswer = (params, callback) => {
	const request = {
		method: 'POST',
		url: `/answers?questionId=${params.questionId}`,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}
