import { React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Skeleton from "@material-ui/lab/Skeleton"
import { getCategories } from "./category.actions"

function Category() {
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		getCategories({ setLoading }, (res, err) => {
			if (!err) {
				setCategories(res._embedded.categories)
			} else {
				console.log("Unable to fetch categories")
			}
		})
	}, [])

	const renderCategories = () => {
		return (
			<ul className="list-group">
				{categories.map((item, index) => {
					return (
						<Link
							key={`category_${index}`}
							className="list-group-item list-group-item-action"
							to={`/counselors?cat=${encodeURIComponent(item.name)}`}
						>
							{item.name}
						</Link>
					)
				})}
			</ul>
		)
	}

	return (
		<>
			<div className="container-fluid bg-light p-5">
				<div className="row m-auto">
					<div className="col-12 m-auto">
						<div className="titleBox">
							<h2>Counselor Category</h2>
						</div>
					</div>
					<div className="col-12 m-auto">
						{categories.length ? (
							<p>
								Please click in any of the following categories to view the
								respective counselors
							</p>
						) : null}
						{loading ? (
							<div>
								<Skeleton
									variant="rect"
									animation="wave"
									width={650}
									height={35}
								/>
								<br />
								<Skeleton
									variant="rect"
									animation="wave"
									width={650}
									height={35}
								/>
								<br />
								<Skeleton
									variant="rect"
									animation="wave"
									width={650}
									height={35}
								/>
							</div>
						) : (
							renderCategories()
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default Category
