import { useState, useContext, useEffect } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AdminSideBar } from '../../AdminSideBar'
import { Dashboard } from '../Dashboard'
import { Categories } from '../Categories'
import { Counselor } from '../Counselor'
import { Question } from '../Question'
import { Answer } from '../Answer'
import { CoreTeam } from '../CoreTeam'
import { ScolarAssists } from '../ScholarAssists'
import { MyToast } from '../../../MyToast'
import { Email } from '../Email'
import { getEmailSettings } from '../Email/email.actions'
import { CloseOutlined, LogoutOutlined } from '@ant-design/icons'
import Dropdown from 'react-bootstrap/Dropdown'
import logo from '../../../../assets/x-logo-white.png'
import { Grid, IconButton, Typography } from '@material-ui/core'

const AdminHome = ({ currentTab, hideToast }) => {
	const [showEmailBar, setShowEmailBar] = useState(false)
	const { toast, apiCallInterpreter, logout } = useContext(AdminContext)

	useEffect(() => {
		fetchEmailSettings()
	}, [])

	const fetchEmailSettings = () =>
		getEmailSettings({ apiCallInterpreter }, (res, err) =>
			!err && res?._embedded?.emailSettings?.length === 0
				? setShowEmailBar(true)
				: setShowEmailBar(false)
		)

	return (
		<>
			{showEmailBar ? (
				<Grid
					container
					justify='space-between'
					alignItems='center'
					style={{ padding: '0 1rem', backgroundColor: 'orange' }}
				>
					<IconButton style={{ padding: '2px' }}></IconButton>
					<Typography
						variant='subtitle1'
						style={{ fontWeight: 'bold' }}
					>
						Please setup an email. If not set then no emails will be
						sent out from the system.
					</Typography>
					<IconButton
						style={{ padding: '2px' }}
						onClick={() => setShowEmailBar(false)}
						aria-label='email-error-bar-close-icon-button'
						edge='end'
					>
						<CloseOutlined style={{ fontSize: '14px' }} />
					</IconButton>
				</Grid>
			) : null}
			<div className='row m-0'>
				<div className='col-lg-12 admin-nav-bar'>
					<span>
						<img src={logo} alt='logo' height='40px' width='auto' />
					</span>
					<Dropdown>
						<Dropdown.Toggle
							id='admin-nav-dropdown'
							className='transparent-bg'
						>
							Admin
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item as='button' onClick={logout}>
								<LogoutOutlined /> &nbsp; Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className='col-lg-2 admin-side-bar p-0'>
					<AdminSideBar />
				</div>
				<div className='col-lg-10 p-0'>
					{/* <div className="row "> */}
					<div className='col-lg-12 bg-light admin-main-content'>
						<MyToast
							message={toast.message}
							visible={toast.visible}
							type={toast.type}
							timeOut={toast.timeOut}
							hideToast={hideToast}
						/>
						<Switch>
							<Route exact path='/admin' component={Dashboard} />
							<Route
								exact
								path='/admin/categories'
								component={Categories}
							/>
							<Route
								exact
								path='/admin/counselor'
								component={Counselor}
							/>
							<Route
								exact
								path='/admin/question'
								component={Question}
							/>
							<Route
								exact
								path='/admin/answer'
								component={Answer}
							/>
							<Route
								exact
								path='/admin/coreteam'
								component={CoreTeam}
							/>
							<Route
								exact
								path='/admin/scholarassists'
								component={ScolarAssists}
							/>
							<Route
								exact
								path='/admin/email'
								component={Email}
							/>
							<Redirect to={`/admin/${currentTab}`} />
						</Switch>
					</div>
					{/* </div> */}
				</div>
			</div>
		</>
	)
}

export default AdminHome
