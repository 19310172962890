import React from "react";
import "./footer.css";

import logo from "../../../assets/x-logo-white.png";

function AppFooter() {
  return (
    <div className="footer">
      <div className="logo">
        <a href="http://google.com">
          <img src={logo} alt="logo" height="50px" width="auto" />
        </a>
      </div>
      <ul className="socials">
        <li>
          <a href="https://www.facebook.com/">
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="https://www.twitter.com/">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
      </ul>
      <div className="copyright">
        Copyright &copy; - Any unauthorized use or reproduction of whole or any
        part of it is strictly prohibited, even if the use is for educational
        purposes. For use for educational purposes, please email to
        xomidhan@gmail.com. Xomidhan reserves all rights to take appropriate
        action, including legal action against any infringement. All disputes
        shall be subject to the exclusive jurisdiction of the courts in Mumbai.
        To contact Xomidhan organization, please send mail to{" "}
        <span style={{ color: "white", fontWeight: "normal" }}>
          xomidhan@gmail.com
        </span>
      </div>
    </div>
  );
}

export default AppFooter;
