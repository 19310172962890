import { useState, useEffect } from "react"
import styles from "./scholarAssist.module.css"
import { getScholarShips } from "./scholarAssist.actions"

function ScholarAssist() {
	const [scholarShips, setScholarShips] = useState([])

	useEffect(() => {
		getScholarShips((res, err) => {
			if (!err) {
				setScholarShips(res._embedded.scholarships)
			} else {
				console.log("Something went wrong!!!")
			}
		})
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row bg-light">
					<div className="col-lg-6 col-sm-12">
						<div className={styles.intro}>
							<div className={styles.titleHolder}>
								<div className="titleBox">
									<h2>Xomidhan Scholar Assist</h2>
								</div>
							</div>
							<p>
								<strong>Xomidhan ScholarAssist</strong> is an innovative digital
								approach to bridge the gap between a donor and a donee.
								Xomidhan, a career counselling platform established on 1 Jan
								2010, though its technological prowess and decade long
								experience is introducing a new digital concept of Scholarship
								as a Service. Donors can avail the expertise of Xomidhan in
								identifying the right candidate and connect them with the right
								scholarship. Xomidhan will work as a mediator as well as
								maintain as steady flow of communication between the donor and
								the donee.
							</p>
						</div>
					</div>

					<div className="col-lg-6 col-sm-12">
						<div className={styles.scholarship}>
							<h5>
								Here is the list of a few scholarships currently available on
								this platform
							</h5>
							<div>
								{scholarShips.length > 0 ? (
									scholarShips.map((item, index) => (
										<p key={index}>
											<li>
												<a href={item.link} rel="noreferrer" target="_blank">
													{item.title}
												</a>
											</li>
										</p>
									))
								) : (
									<p>No Scholarship Available.</p>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className={styles.faqBlock}>
						<h1>
							<strong>FAQ</strong>
						</h1>
						<h4> What is Xomidhan ?</h4>
						<div className={styles.answer}>
							Xomidhan means uttar, an answer or a response as per Assamese
							language. This is an initiative started on 1 Jan 2010 to provide
							free career counseling to the students of Northeast India. The
							query comes to Xomidhan via the web portal (www.xomidhan.org) and
							Xomidhan sources the answers from its pool of volunteer counselors
							who are domain experts in various career disciplines. From another
							perspective, Xomidhan is a platform through which the
							professionals from NE region can connect to the student fraternity
							and provide intellectual assistance.
						</div>
						<h4>What is ScholarAssist ?</h4>
						<div className={styles.answer}>
							<p>
								ScholarAssist is an initiative by Xomidhan to act as an
								aggregator platform through which a donor can publish and select
								prospective student for providing financial assistance.
							</p>
						</div>
						<h4>What is the necessity of a platform such as ScholarAssist ?</h4>
						<div className={styles.answer}>
							A data analysis of the queries received at Xomidhan over the last
							decade has revealed that a quarter of the students' queries are
							related to financial assistance. The core team of Xomidhan
							presented this finding into various forums. Several people came
							forward with the willingness to provide financial help as a micro
							donor if institution like Xomidhan act as middle layer for
							facilitation and verification. This has led to the ambitious
							initiative of ScholarAssist.
						</div>
						<h4>What is the necessity of a platform such as ScholarAssist ?</h4>
						<div className={styles.answer}>
							A data analysis of the queries received at Xomidhan over the last
							decade has revealed that a quarter of the students' queries are
							related to financial assistance. The core team of Xomidhan
							presented this finding into various forums. Several people came
							forward with the willingness to provide financial help as a micro
							donor if institution like Xomidhan act as middle layer for
							facilitation and verification. This has led to the ambitious
							initiative of ScholarAssist.
						</div>
						<h4> Who is a Xomidhan ScholarAssist micro donor ?</h4>
						<div className={styles.answer}>
							A Xomidhan ScholarAssist micro donor is an individual who is
							associated with Xomidhan and has agreed to collaborate with
							Xomidhan to provide need based or merit based scholarship for a
							specific duration of time or on a recurring long term basis.
						</div>
						<h4>What is the minimum and maximum donation amount per year ?</h4>
						<div className={styles.answer}>
							Minimum per year donation is Rs.5000 and maximum per year is Rs.
							100000 per year
						</div>
						<h4>Who decides the criteria for scholarship ?</h4>
						<div className={styles.answer}>
							Donors will decide the minimum criteria for scholarship, they will
							have to provide the guidelines depending on their interests. Only
							requirement is to have the guidelines based on educational
							performance and the family earnings.Xomidhan will not allow any
							religion or caste based guidelines.
						</div>
						<h4>Who will decide the scholarship recipient ?</h4>
						<div className={styles.answer}>
							Xomidhan will build a platform where all the applicants details
							will be routed to the donor directly and s/he will decide the
							recipient based on the guidelines provided by them. The recipient
							has to meet the minimum guidelines set originally by the donor.
						</div>
						<h4> Where can I find sample guidelines for the scholarship ?</h4>
						<div className={styles.answer}>
							Xomidhan will provide sample guidelines which can be tweaked as
							per donors' wishes.
						</div>
						<h4>Who and how will the recipient be decided ?</h4>
						<div className={styles.answer}>
							Donors are the owners of deciding the recipient, from all the
							application that are received, donors will provide a priority list
							of 3 candidates to Xomidhan after which Xomidhan will verify the
							details given by the recipients and will be awarded as per
							priority.
						</div>
						<h4>
							I am a student in urgent need of scholarship ? Should I email my
							requirement as I don't match the eligibility of any listed
							scholarship currently ?
						</h4>
						<div className={styles.answer}>
							No, emails are not entertained currently. Only the filled up
							applications against a listed scholarship are considered.
						</div>
						<h4>Is Xomidhan making money from ScholarAssist ?</h4>
						<div className={styles.answer}>
							Xomidhan is keeping 5% from the total committed amount by the
							donor as administrative charge.
						</div>
						<h4>
							Applying to scholarships does not in any way guarantee the award
							of scholarship.
						</h4>
						<div className={styles.answer}>
							Xomidhan reserves the right to withdraw or amend any listed
							scholarship without prior notification.
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ScholarAssist
