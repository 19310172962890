import { useState, useEffect } from "react"
import { TeamMember } from "./TeamMember"
import { getCoreTeamMembers } from "./xomidhanTeam.actions"

function XomidhanTeam() {
	const [team, setTeam] = useState([])

	useEffect(() => {
		getCoreTeamMembers((res, err) => {
			if (!err) {
				setTeam([
					...res._embedded.counsellors.map(item => ({
						...item,
						altText: item.name.split(" ")[0].toLowerCase(),
						image: item.hasPhoto ? `${item._links.self.href}/pic` : null,
					})),
				])
			} else {
				console.log("something went wrong!!!")
			}
		})
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row bg-light">
					<div className="col-12">
						<div
							className="titleBox"
							style={{
								paddingTop: "40px",
								paddingLeft: "40px",
								paddingBottom: "20px",
							}}
						>
							<h2>Xomidhan Core Team</h2>
						</div>
					</div>
				</div>
				<div className="row bg-light gutter-2">
					{team.map((item, index) => {
						return (
							<div
								key={`teammember_${index}`}
								className="col-lg-12 col-md-12 col-sm-12 pb-3 m-auto"
							>
								<TeamMember
									image={item.image}
									altText={item.altText}
									name={item.name}
									profession={item.currentProfession}
									education={item.educationalQualification}
									desc={item.aboutMe}
								/>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default XomidhanTeam
