import store from "store"

export const storeData = (key, value, callback) => {
	try {
		store.set(key, value)
		callback(true)
	} catch (e) {
		callback(false)
		console.log("Error in saving data", e)
	}
}

export const getData = (key, callback, failed) => {
	try {
		const value = store.get(key)
		callback(value)
	} catch (e) {
		failed(e)
		console.log("Error in getting data")
	}
}

export const removeData = key => {
	try {
		store.remove(key)
	} catch (e) {
		console.log("Error in removing data")
	}
}

export const removeAllData = (keys, callback) => {
	try {
		const value = store.clearAll(keys)
		callback(value)
	} catch (e) {
		console.log("Error in removing data")
	}
}
