import app from "../../utils/apiCall"

export const getCounselors = (paramValue, callback) => {
	let request = {
		method: "GET",
		url: "/counsellors/search/findByCategory",
		params: {
			category: paramValue.category,
		},
	}
	paramValue.setLoading(true)
	app.invokeApi(request, (res, err) => {
		paramValue.setLoading(false)
		callback(res, err)
	})
}
