import { useState, useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import * as categoryActions from './categories.actions'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import { Loading } from '../Loading'
import { Pagination } from '../Pagination'
import AddCategory from './AddCategory'
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import styles from '../../xomidhanAdmin.module.css'
import { itemsPerPage } from '../../../../utils/constant'

const Categories = () => {
	const [modalShow, setModalShow] = useState(false) // for modal
	const [category, setCategory] = useState({ name: '', description: '' }) // local category
	const [categories, setCategories] = useState([]) // all categories list
	const [onEdit, setOnEdit] = useState(false) // switch between post and put for modal
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination

	// global states
	const {
		isLoading,
		setIsLoading,
		setCurrentTab,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('categories')
	}, [])

	useEffect(() => {
		getCategories()
	}, [currentPage])

	const getCategories = () => {
		setIsLoading(true)
		categoryActions.getCategories(
			{ page: currentPage - 1, size: itemsPerPage, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setTotalPages(res.page.totalPages)
					setCategories([...res._embedded.categories])
				}
			}
		)
	}

	const addCategory = () => {
		setIsLoading(true)
		const data = { name: category.name, description: category.description }
		categoryActions.addCategory(
			{ data, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'New Category Added Successfully',
						type: '',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getCategories()
			}
		)
	}

	const updateCategory = () => {
		setIsLoading(true)
		const data = { name: category.name, description: category.description }
		const url = category._links.self.href
		categoryActions.updateCategory(
			{ url, data, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Category Updated Successfully',
						type: '',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getCategories()
			}
		)
	}

	const removeCategory = (category) => {
		setIsLoading(true)
		const url = category._links.self.href
		categoryActions.removeCategory(
			{ url, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Category Deleted Successfully',
						type: '',
					})
				} else {
					let message = ''
					if (res.errorCode === 3002) {
						message =
							'You cannot delete this Category as Counselors under this Category already exists.'
					} else {
						message = 'Something went Wrong!!! Try Again...'
					}
					setToast({
						...toast,
						visible: true,
						message: message,
						type: 'failure',
					})
				}
				getCategories()
			}
		)
	}

	// for category state update from modal
	const handleCategoryUpdate = (e) =>
		setCategory({ ...category, [e.target.name]: e.target.value })

	// to fire when an update button is clicked
	const onUpdateClick = (category) => {
		setCategory(category)
		setOnEdit(true)
		setModalShow(true)
	}

	return (
		<>
			{/* Header */}
			<div className={`${styles['flex-div']} mb-3`}>
				{/* Title */}
				<div className='titleBox'>
					<h2>Categories</h2>
				</div>
			</div>

			{/* Add Category Modal */}
			<AddCategory
				// for modal:
				show={modalShow}
				onHide={() => setModalShow(false)}
				// category to edit
				category={category}
				setCategory={setCategory}
				handleCategoryUpdate={handleCategoryUpdate}
				// post vs put
				onEdit={onEdit}
				setOnEdit={setOnEdit}
				// functions
				addCategory={addCategory}
				updateCategory={updateCategory}
			/>

			{/* Categories */}
			{isLoading ? (
				<Loading />
			) : categories.length > 0 ? (
				<div className='table-responsive'>
					<table className='table table-hover'>
						<thead>
							<tr>
								<th>Name</th>
								<th>Description</th>
								<th className={styles['flex-end-div']}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{categories.map((category, index) => (
								<tr key={`category_${index}`}>
									<td>{category.name}</td>
									<td>{category.description}</td>
									<td className={styles['flex-end-div']}>
										<Dropdown as={ButtonGroup} size='sm'>
											<Button
												variant='warning'
												onClick={() =>
													onUpdateClick(category)
												}
											>
												<EditFilled />
												Edit
											</Button>

											<Dropdown.Toggle
												split
												variant='warning'
											/>

											<Dropdown.Menu className='p-0'>
												<Dropdown.Item
													className='bg-danger text-white'
													onClick={() =>
														removeCategory(category)
													}
												>
													<DeleteFilled />
													Remove
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{totalPages > 1 && (
						<Pagination
							name={'Categories'}
							currentPage={currentPage}
							totalPages={totalPages}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</div>
			) : (
				<>
					<h3>No category available to show</h3>
				</>
			)}
			{/* Add Category Button */}
			<Button
				variant='success'
				onClick={() => setModalShow(true)}
				className='my-3'
			>
				<strong>+</strong>&nbsp;Add New Category
			</Button>
		</>
	)
}

export default Categories
