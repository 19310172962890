import React from 'react'
import {
	CaretLeftFilled,
	CaretRightFilled,
	BackwardFilled,
	ForwardFilled,
} from '@ant-design/icons'

const Pagination = ({ name, currentPage, totalPages, setCurrentPage }) => {
	return (
		<div>
			<nav aria-label={`${name}_Pagination`}>
				<ul className='pagination justify-content-center mt-5'>
					{/* Before content... */}
					{currentPage > 1 ? (
						<>
							<li className='page-item '>
								<a
									className='page-link'
									onClick={() => setCurrentPage(1)}
								>
									<BackwardFilled />
								</a>
							</li>
							<li className='page-item '>
								<a
									className='page-link'
									onClick={() =>
										setCurrentPage(currentPage - 1)
									}
								>
									<CaretLeftFilled />
								</a>
							</li>
						</>
					) : (
						<>
							<li className='page-item disabled'>
								<span className='page-link'>
									<BackwardFilled />
								</span>
							</li>
							<li className='page-item disabled'>
								<span className='page-link'>
									<CaretLeftFilled />
								</span>
							</li>
						</>
					)}

					{/* ... */}
					{currentPage - 1 > 1 && (
						<li className='page-item disabled'>
							<span className='page-link'>...</span>
						</li>
					)}

					{/* Previous page */}
					{currentPage > 1 && (
						<li className='page-item'>
							<a
								className='page-link'
								onClick={() => setCurrentPage(currentPage - 1)}
							>
								{currentPage - 1}
							</a>
						</li>
					)}
					<li className='page-item active' aria-current='page'>
						<span className='page-link'>{currentPage}</span>
					</li>
					{/* Next page */}
					{currentPage < totalPages && (
						<li className='page-item'>
							<a
								className='page-link'
								onClick={() => setCurrentPage(currentPage + 1)}
							>
								{currentPage + 1}
							</a>
						</li>
					)}

					{/* ... */}
					{currentPage + 1 < totalPages && (
						<li className='page-item disabled'>
							<span className='page-link'>...</span>
						</li>
					)}

					{/* After content... */}
					{currentPage < totalPages ? (
						<>
							<li className='page-item'>
								<a
									className='page-link'
									onClick={() =>
										setCurrentPage(currentPage + 1)
									}
								>
									<CaretRightFilled />
								</a>
							</li>
							<li className='page-item'>
								<a
									className='page-link'
									onClick={() => setCurrentPage(totalPages)}
								>
									<ForwardFilled />
								</a>
							</li>
						</>
					) : (
						<>
							<li className='page-item disabled'>
								<span className='page-link'>
									<CaretRightFilled />
								</span>
							</li>
							<li className='page-item disabled'>
								<span className='page-link'>
									<ForwardFilled />
								</span>
							</li>
						</>
					)}
				</ul>
			</nav>
		</div>
	)
}

export default Pagination
