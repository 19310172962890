import React, { useState, useEffect } from "react"
import { sessionIsActive } from "../../utils/apiCall"
import { refresh_login } from "./Views/Login/login.actions"
import { Route, Redirect } from "react-router-dom"
import { storeData, removeData } from "../../utils/asyncData"
import { Login } from "./Views/Login"
import { AdminHome } from "./Views/AdminHome"
import app from "../../utils/apiCall"

export const AdminContext = React.createContext()

function XomidhanAdmin() {
	const [isLoading, setIsLoading] = useState(false) // loading
	const [isLoggedIn, setIsLoggedIn] = useState(false) // login
	const [authResponse, setAuthResponse] = useState({}) // login response
	const [currentTab, setCurrentTab] = useState("") // to store current tab from the admin panel
	// alert toast
	const [toast, setToast] = useState({
		message: "",
		visible: false,
		timeOut: 5000,
		type: "success",
	})

	useEffect(() => {
		setIsLoading(true)
		sessionIsActive((isActive, tab) => {
			setCurrentTab(tab)
			if (isActive) {
				setIsLoggedIn(true)
				setIsLoading(false)
			} else {
				if (authResponse.hasOwnProperty("refresh_token")) {
					refresh_login(authResponse.refresh_token, (status, res) => {
						if (status) {
							setAuthResponse(res)
							setIsLoggedIn(true)
						} else {
							setIsLoggedIn(false)
						}
						setIsLoading(false)
					})
				} else {
					setIsLoggedIn(false)
					setIsLoading(false)
				}
			}
		})
	}, [])

	useEffect(() => {
		storeData("adminTab", currentTab, status => {
			status ? console.log("tab updated") : console.log("something went wrong")
		})
	}, [currentTab])

	// logout function
	const logout = () => {
		removeData("token")
		removeData("adminTab")
		setAuthResponse({})
		setIsLoading(false)
		setIsLoggedIn(false)
	}

	// function to interprete api calls
	const apiCallInterpreter = (request, callback) => {
		sessionIsActive((isActive, tab) => {
			console.log(tab)
			if (isActive) {
				app.invokeApi(request, callback)
			} else {
				if (authResponse.hasOwnProperty("refresh_token")) {
					refresh_login(authResponse.refresh_token, (status, res) => {
						if (status) {
							setToast({
								...toast,
								message: "Refreshing login",
								visible: true,
								type: "warning",
							})
							setAuthResponse(res)
							app.invokeApi(request, callback)
						} else {
							setToast({
								...toast,
								message:
									"Token and Refresh Token both expired. Please Login Again.",
								visible: true,
								timeOut: 2000,
								type: "failure",
							})
							setTimeout(() => {
								logout()
							}, 2000)
						}
					})
				} else {
					setToast({
						...toast,
						message: "Token Expired. Please Login Again.",
						visible: true,
						timeOut: 2000,
						type: "failure",
					})
					setTimeout(() => {
						logout()
					}, 2000)
				}
			}
		})
	}

	// function to dismiss toast
	const hideToast = () => {
		setToast({ ...toast, visible: false })
	}

	return (
		<AdminContext.Provider
			value={{
				isLoading,
				setIsLoading,
				setIsLoggedIn,
				setAuthResponse,
				setCurrentTab,
				toast,
				setToast,
				logout,
				apiCallInterpreter,
			}}
		>
			<Route exact path="/admin/login" component={Login} />
			{isLoggedIn ? (
				<AdminHome currentTab={currentTab} hideToast={hideToast} />
			) : (
				<Redirect to="/admin/login" />
			)}
		</AdminContext.Provider>
	)
}

export default XomidhanAdmin
