import { useState } from "react"
import { NavLink, Link } from "react-router-dom"
import logo from "../../../assets/x-logo.png"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Dropdown from "react-bootstrap/Dropdown"

function AppNavBar() {
	const [showDropdown, setShowDropdown] = useState(false)

	return (
		<>
			<Navbar
				sticky="top"
				collapseOnSelect
				expand="lg"
				bg="light"
				variant="light"
				className="nav-bar"
			>
				<Navbar.Brand to="/">
					<img src={logo} alt="logo" height="40px" width="auto" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto">
						<NavLink
							exact
							to="/"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							Home
						</NavLink>

						<NavLink
							exact
							to="/about"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							About
						</NavLink>

						<NavLink
							exact
							to="/coreteam"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							Core Team
						</NavLink>

						<Dropdown
							show={showDropdown}
							onMouseOver={() => setShowDropdown(true)}
							onMouseOut={() => setShowDropdown(false)}
						>
							<NavLink
								exact
								to="/categories"
								className="nav-link nav-links"
								activeClassName="active-nav-links"
								isActive={(match, location) => {
									if (!match) {
										if (
											location.pathname === "/counselors" ||
											location.pathname === "/categories"
										) {
											return true
										}
										return false
									} else {
										if (match.url === location.pathname) {
											return true
										} else {
											return false
										}
									}
								}}
							>
								Counselor
								<Dropdown.Toggle
									as="button"
									className="btn btn-sm"
									disabled
								></Dropdown.Toggle>
							</NavLink>

							<Dropdown.Menu style={{ marginTop: 0 }}>
								<Dropdown.Item
									as={Link}
									to="/modification/profile"
									varient="link"
								>
									Update Profile
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						<NavLink
							exact
							to="/ask"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							Ask Question
						</NavLink>

						<NavLink
							exact
							to="/join"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							Join Xomidhan
						</NavLink>

						<NavLink
							exact
							to="/scholarassist"
							className="nav-link nav-links"
							activeClassName="active-nav-links"
						>
							Scholar Assist
						</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	)
}
export default AppNavBar
