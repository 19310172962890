import app from './apiCall'
import { storeData } from './asyncData'

export const itemsPerPage = 20

export const authenticate = (grant_type, body, callback) => {
	var params = {
		grant_type: grant_type,
	}
	if (body.refresh_token !== undefined) {
		params.refresh_token = body.refresh_token
	}
	if (body.username !== undefined) {
		params.username = body.username
	}

	if (body.password !== undefined) {
		params.password = body.password
	}

	if (body.scope !== undefined) {
		params.scope = body.scope
	}

	var formBody = []
	for (var property in params) {
		var encodedKey = encodeURIComponent(property)
		var encodedValue = encodeURIComponent(params[property])
		formBody.push(encodedKey + '=' + encodedValue)
	}
	formBody = formBody.join('&')
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: 'Basic eG8tYWRtaW4tYXBwOnhvLWFkbWluLWFwcC1zZWNyZXQ=',
	}
	let options = {
		method: 'POST',
		url: '/oauth/token',
		headers: headers,
		data: formBody,
	}
	app.invokeApi(options, async (res, err) => {
		if (!err) {
			res.expiresAt = getExpireTime(res.expires_in) // storing expire time in milliseconds
			// console.log(res.expiresAt)
			storeData('token', { ...res }, (status) => {
				if (status) {
					callback(true, res)
				} else {
					callback(false, res)
				}
			})
		} else {
			callback(false, res)
		}
	})
}

// function to get the expire time of the auth token
const getExpireTime = (expireInSeconds) => {
	const now = +new Date() // getting current time in milliseconds
	return now + expireInSeconds * 1000
}

export const recaptcha_site_key = '6LeSCw8iAAAAAOPcZMPSW7zYqJEWEsmjbHEyviy_'
export const recaptcha_site_key_prod='6Lc8R0siAAAAANRCJm9AOCAlGYI0v_Ljszs1AGaf'