export const getCoreTeamMembers = (params, callback) => {
	const request = {
		method: 'GET',
		url: `/counsellors/coreTeamMembers?page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const putCoreTeamMembers = (params, callback) => {
	const request = {
		method: 'PATCH',
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}
