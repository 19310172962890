import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import styles from "../../xomidhanAdmin.module.css"

const AddCategory = ({
	show,
	onHide,
	category,
	setCategory,
	handleCategoryUpdate,
	onEdit,
	setOnEdit,
	addCategory,
	updateCategory,
}) => {
	const refreshModal = () => {
		setCategory({ name: "", description: "" })
		setOnEdit(false)
		onHide()
	}
	const handleSubmit = e => {
		e.preventDefault()
		if (onEdit) {
			updateCategory()
		} else {
			addCategory()
		}
		refreshModal()
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop="static"
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{onEdit ? "Edit Category" : "Add New Category"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit}>
					<input
						type="text"
						name="name"
						placeholder="Name"
						value={category.name}
						onChange={handleCategoryUpdate}
						className={styles["form-input"]}
						required
					/>
					<textarea
						name="description"
						placeholder="Description"
						value={category.description}
						onChange={handleCategoryUpdate}
						className={styles["form-textarea"]}
						required
					></textarea>
					<Button
						onClick={refreshModal}
						type="button"
						className="mr-2 btn btn-sm btn-warning"
					>
						Cancel
					</Button>
					<Button type="submit" className="btn btn-sm btn-success">
						{onEdit ? "Update" : "Add"}
					</Button>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default AddCategory
