import { useState, useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import { AskQuestion } from '../../../AskQuestion'
import { AnswerForm } from '../Answer'
import { Loading } from '../Loading'
import * as questionActions from './questions.actions'
import {
	Tab,
	Tabs,
	Table,
	Button,
	ButtonGroup,
	Dropdown,
	Modal,
	Form,
} from 'react-bootstrap'
import {
	EditFilled,
	DeleteFilled,
	MailFilled,
	SolutionOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons'
import { Pagination } from '../Pagination'
import { itemsPerPage } from '../../../../utils/constant'

function Question() {
	const [questions, setQuestions] = useState([])
	const [categories, setCategories] = useState([])
	const [pageStatus, setPageStatus] = useState('AT')
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination

	// global states
	const {
		isLoading,
		setIsLoading,
		setCurrentTab,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('question')
		setIsLoading(true)
		questionActions.getCategories({ apiCallInterpreter }, (res, err) => {
			if (!err) {
				setCategories(res._embedded.categories)
			} else {
				setToast({
					...toast,
					visible: true,
					message: 'Something went Wrong!!! Refresh the page...',
					type: 'failure',
				})
			}
			setIsLoading(false)
		})
	}, [])

	useEffect(() => {
		getQuestions(pageStatus)
	}, [pageStatus, currentPage])

	const getQuestions = (type) => {
		setIsLoading(true)
		questionActions.getQuestions(
			{
				page: currentPage - 1,
				size: itemsPerPage,
				type,
				apiCallInterpreter,
			},
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setQuestions([...res._embedded.questions])
					setTotalPages(res.page.totalPages)
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
			}
		)
	}

	const updateQuestion = (question, url, method) => {
		setIsLoading(true)
		questionActions.updateQuestion(
			{ url, data: question, method, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Question updated',
						type: 'success',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getQuestions(pageStatus)
			}
		)
	}

	const removeQuestion = (url) => {
		setIsLoading(true)
		questionActions.removeQuestion(
			{ url, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Question deleted',
						type: 'success',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getQuestions(pageStatus)
			}
		)
	}

	const submitAnswer = (questionId, data) => {
		setIsLoading(true)
		questionActions.submitAnswer(
			{ questionId, data, apiCallInterpreter },
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Answer Submittted Successfully',
						type: 'success',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getQuestions(pageStatus)
			}
		)
	}

	return (
		<>
			<div className='titleBox mb-3'>
				<h2>Questions</h2>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<Tabs
					activeKey={pageStatus}
					onSelect={(k) => setPageStatus(k)}
					className='mt-4'
				>
					{['AT', 'others'].map((item, index) => (
						<Tab
							eventKey={item}
							title={
								item === 'others'
									? 'Questions from Others'
									: 'Questions from Assam Tribune'
							}
							key={index}
						>
							<QuestionList
								questions={questions}
								categories={categories}
								updateQuestion={updateQuestion}
								removeQuestion={removeQuestion}
								submitAnswer={submitAnswer}
							/>
							{totalPages > 1 && (
								<Pagination
									name={'Question'}
									currentPage={currentPage}
									totalPages={totalPages}
									setCurrentPage={setCurrentPage}
								/>
							)}
						</Tab>
					))}
				</Tabs>
			)}
		</>
	)
}

const QuestionList = ({
	questions,
	categories,
	updateQuestion,
	removeQuestion,
	submitAnswer,
}) => {
	const [showDetailsModal, setShowDetailsModal] = useState(false)
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [showEditQuestionModal, setShowEditQuestionModal] = useState(false)
	const [showAnswerModal, setShowAnswerModal] = useState(false)
	const [editQuestion, setEditQuestion] = useState({})
	const [selectedCats, setSelectedCats] = useState([])

	const categoriesSubmit = (e) => {
		e.preventDefault()
		let temp = { ...editQuestion }
		temp.phone = temp.phone !== null ? temp.phone.number : null;
		delete temp._links
		delete temp.createdAt
		delete temp.modifiedAt
		delete temp.isDeleted
		delete temp.mailSentCount
		temp.categories = selectedCats
		updateQuestion(temp, editQuestion._links.self.href, 'PUT')
		setShowEmailModal(false)
		setEditQuestion({})
	}

	const handleAnswerSubmit = (answer) =>
		submitAnswer(editQuestion._links.self.href.split('/').at(-1), answer)

	return (
		<>
			{/* Question Details modal */}
			<Modal
				show={showDetailsModal}
				onHide={() => setShowDetailsModal(false)}
				size='md'
				aria-labelledby='showQuestionDetailsModal'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='showQuestionDetailsModal'>
						Question Details
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<strong>Question: </strong>
					{editQuestion.question} <br />
					<strong>Current Education: </strong>
					{editQuestion.currentEducation} <br />
					<strong>Marks In 10th and 12th: </strong>
					{editQuestion.marksIn10and12th} <br />
					<strong> Educational Background: </strong>
					{editQuestion.educationalBackground} <br />
					<strong>Aim: </strong>
					{editQuestion.aim} <br />
				</Modal.Body>
			</Modal>

			{/* Edit Question modal */}
			<Modal
				show={showEditQuestionModal}
				onHide={() => setShowEditQuestionModal(false)}
				size='lg'
				aria-labelledby='showQuestionDetailsModal'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='showQuestionDetailsModal'>
						Edit Question
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AskQuestion
						question={editQuestion}
						updateQuestion={updateQuestion}
						setShowEditQuestionModal={setShowEditQuestionModal}
					/>
				</Modal.Body>
			</Modal>

			{/* Send Email modal */}
			<Modal
				show={showEmailModal}
				onHide={() => setShowEmailModal(false)}
				size='md'
				aria-labelledby='questionEmail'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='questionEmail'>
						Send Email to Counselors
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={categoriesSubmit}>
						<Form.Group>
							<Form.Label>
								Select categories to send email to:
							</Form.Label>
							<Form.Control
								as='select'
								multiple
								onChange={(e) =>
									setSelectedCats(
										[...e.target.selectedOptions].map(
											(option) => option.value
										)
									)
								}
							>
								{categories.length > 0 ? (
									categories.map((item, index) => (
										<option
											key={index}
											value={item._links.self.href}
										>
											{item.name}
										</option>
									))
								) : (
									<option key='error_loading'>
										Unable to fetch categories
									</option>
								)}
							</Form.Control>
						</Form.Group>
						<Button variant='primary' type='submit'>
							Submit
						</Button>
					</Form>
				</Modal.Body>
			</Modal>

			{/* Answer modal */}
			<Modal
				show={showAnswerModal}
				onHide={() => setShowAnswerModal(false)}
				size='lg'
				aria-labelledby='showQuestionDetailsModal'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='showQuestionDetailsModal'>
						Submit Answer
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AnswerForm submitAnswer={handleAnswerSubmit} />
				</Modal.Body>
			</Modal>

			{/* Questions list */}
			{questions.length > 0 ? (
				<Table className='pt-2'>
					<thead>
						<tr>
							<th colSpan={2}>Question</th>
							<th style={{ textAlign: 'center' }}>Mail Count</th>
							<th style={{ textAlign: 'right' }}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{questions.map((question, index) => {
							return (
								<tr key={`question_${index}`}>
									<td colSpan={2}>
										{index + 1} &nbsp;
										{question.question}
									</td>
									<td style={{ textAlign: 'center' }}>
										{question.mailSentCount || 0}
									</td>
									<td style={{ textAlign: 'right' }}>
										<Dropdown as={ButtonGroup} size='sm'>
											<Button
												style={{
													minWidth: '5rem',
													padding: '0.25rem 0.125rem',
												}}
												onClick={() => {
													setEditQuestion(question)
													setShowDetailsModal(true)
												}}
											>
												<InfoCircleOutlined />
												&nbsp; Details
											</Button>

											<Dropdown.Toggle split />

											<Dropdown.Menu className='p-0'>
												<Dropdown.Item
													className='bg-warning'
													onClick={() => {
														setEditQuestion(
															question
														)
														setShowEditQuestionModal(
															true
														)
													}}
												>
													<EditFilled /> &nbsp; Edit
												</Dropdown.Item>
												<Dropdown.Item
													className='bg-info text-white'
													onClick={() => {
														setEditQuestion(
															question
														)
														setShowEmailModal(true)
													}}
												>
													<MailFilled /> &nbsp; Send
													Email
												</Dropdown.Item>
												<Dropdown.Item
													className='bg-success text-white'
													onClick={() => {
														setEditQuestion(
															question
														)
														setShowAnswerModal(true)
													}}
												>
													<SolutionOutlined /> &nbsp;
													Answer
												</Dropdown.Item>
												<Dropdown.Item
													className='bg-danger'
													onClick={() =>
														removeQuestion(
															question._links.self
																.href
														)
													}
												>
													<DeleteFilled /> &nbsp;
													Remove
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			) : (
				<h3 className='pt-2 pl-3'>No Questions Available.</h3>
			)}
		</>
	)
}

export default Question
