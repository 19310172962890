import axios from 'axios'
import { getData } from './asyncData'
import promise from 'promise'

export const APP_ENVIRONMENT = 'prod'

let axiosBaseUrl
// let axiosBaseUrl =  window.location.origin;

if (APP_ENVIRONMENT === 'local') {
	axiosBaseUrl = 'http://localhost:9000'
} else {
	// appOrigin = "http://159.203.115.251"
	axiosBaseUrl = 'https://xomidhan.org'
}

let axiosInstance = axios.create({
	baseURL: axiosBaseUrl,
	timeout: 60000,
	withCredentials: false,
})

const basicAuthExceptions = [
	{ url: /^\/search.*/, method: 'get' },
	{ url: /\/categories.*/, method: 'get' },
	{ url: /\/counsellors\?.*/, method: 'get' },
	{ url: /\/counsellors\/coreTeamMembers.*/, method: 'get' },
	{ url: /\/counsellors\/search\/findByCategory.*/, method: 'get' },
	{ url: /\/questions.*/, method: 'post' },
	{ url: /\/scholarships.*/, method: 'get' },
	{ url: /^\/counsellors.*$/, method: 'post' },
	{ url: /^\/otps.*$/, method: 'post' },
]

// function to check if the invoked url needs an access token or not
const checkAccessTokenRequirement = (url, method) =>
	!basicAuthExceptions.some(
		(item) =>
			item.url.test(url) && (item.method === '' || item.method === method)
	)

axiosInstance.interceptors.request.use(
	function (config) {
		let needAccessToken = true
		needAccessToken = checkAccessTokenRequirement(config.url, config.method)
		let accessToken = ''
		// Do something before request is sent
		// If the header does not contain the token and the url not public, redirect to login
		if (needAccessToken && config.url !== '/oauth/token') {
			getData(
				'token',
				(res) => (accessToken = res.access_token),
				(e) => console.log(e)
			)
			// if token is found add it to the header
			if (accessToken) {
				if (config.method !== 'OPTIONS') {
					// axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
					config.headers.Authorization = `Bearer ${accessToken}`
				}
			}
		} else {
			config.headers.Authorization =
				'Basic eG8tYWRtaW4tYXBwOnhvLWFkbWluLWFwcC1zZWNyZXQ='
		}
		return config
	},
	function (error) {
		// Do something with request error
		return promise.reject(error)
	}
)

class ApiCall {
	invokeApi = (request, callback) => {
		let config = {
			method: request.method,
			url: request.url,
		}
		if (request.headers !== undefined) {
			// request.headers.Origin = appOrigin
			config.headers = request.headers
		} else {
			config.headers = {
				'Content-Type': 'application/json',
			}
		}
		if (request.data !== undefined) {
			config.data = request.data
		}
		if (request.params !== undefined) {
			config.params = request.params
		}

		if (request.responseType !== undefined) {
			config.responseType = request.responseType
		}
		axiosInstance
			.request(config)
			.then((res) => {
				let data = res.data

				if (APP_ENVIRONMENT === 'prod') {
					var old = JSON.stringify(res.data).replace(/http/g, 'https') //convert to JSON string
					console.log('old', old)
					data = JSON.parse(old) //convert back to array
				}
				callback(data, false)
			})
			.catch((err) => {
				console.log('Fetch Err ', err)
				if (err.response !== undefined) {
					callback(err.response.data, true)
				} else {
					callback(undefined, true)
				}
			})
	}
}

// function to check if the access token is still valid
export const sessionIsActive = (callback) => {
	let isActive = false
	let tab = ''
	getData(
		'token',
		(res) => {
			const now = new Date()
			const expireTime = new Date(res.expiresAt)
			isActive = now < expireTime ? true : false
			getData(
				'adminTab',
				(res) => {
					tab = res
					isActive ? callback(isActive, tab) : callback(isActive, '')
				},
				(e) => {
					console.log(e)
					callback(isActive, '')
				}
			)
		},
		(e) => callback(false, '')
	)
}

const app = new ApiCall()
export default app
