import { authenticate } from "../../../../utils/constant"

export const login = (paramValues, callback) => {
	const grant_type = "password"
	const body = {
		username: paramValues.user,
		password: paramValues.pass,
		scope: "read",
	}
	authenticate(grant_type, body, (status, res) => {
		callback(status, res)
	})
}

export const refresh_login = (refresh_token, callback) => {
	const grant_type = "refresh_token"
	const body = {
		refresh_token,
		scope: "read",
	}
	authenticate(grant_type, body, (status, res) => {
		callback(status, res)
	})
}
