import { useState, useEffect, useContext } from 'react'
import { Loading } from '../Loading'
import { AdminContext } from '../../XomidhanAdmin'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import * as scholarShipActions from './scholarAssists.actions'
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core'
import { Pagination } from '../Pagination'
import { itemsPerPage } from '../../../../utils/constant'

const ScolarAssists = () => {
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination
	const [scolarships, setScolarships] = useState([])
	const [activeFilter, setActiveFilter] = useState('true')
	const [onEdit, setOnEdit] = useState(false)
	const [currentScholarship, setCurrentScholarship] = useState({
		title: '',
		link: '',
		status: false,
	})
	const [showAddScholarshipModal, setShowAddScholarshipModal] =
		useState(false)
	const {
		isLoading,
		setCurrentTab,
		setIsLoading,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('scholarassists')
	}, [])

	useEffect(() => {
		getScholarShips()
	}, [activeFilter, currentPage])

	const getScholarShips = () => {
		setIsLoading(true)
		scholarShipActions.getScholarShips(
			{
				status: activeFilter,
				apiCallInterpreter,
				page: currentPage - 1,
				size: itemsPerPage,
			},
			(res, err) => {
				if (!err) {
					setTotalPages(res.page.totalPages)
					setScolarships(res._embedded.scholarships)
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went wrong',
						type: 'failure',
					})
				}
				setIsLoading(false)
			}
		)
	}

	const addScholarship = () => {
		setIsLoading(true)
		scholarShipActions.addScholarShip(
			{ data: currentScholarship, apiCallInterpreter },
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'New Scholarship Added Successfully',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				setIsLoading(false)
				getScholarShips()
			}
		)
	}
	const updateScholarship = (url, data) => {
		setIsLoading(true)
		scholarShipActions.editScholarship(
			{ url, data, apiCallInterpreter },
			(res, err) => {
				err
					? setToast({
							...toast,
							visible: true,
							message: 'Something went wrong!!!',
							type: 'failure',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Scholarship Updated',
							type: '',
					  })
				setIsLoading(false)
				getScholarShips()
			}
		)
	}
	const removeScholarship = (url) => {
		setIsLoading(true)
		scholarShipActions.removeScholarship(
			{ url, apiCallInterpreter },
			(res, err) => {
				err
					? setToast({
							...toast,
							visible: true,
							message: 'Something went wrong!!!',
							type: 'failure',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Scholarship removed',
							type: '',
					  })
				setIsLoading(false)
				getScholarShips()
			}
		)
	}

	return (
		<>
			<div
				className='titleBox mb-3'
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<h2>Scholarships</h2>
				<FormControl component='fieldset'>
					<RadioGroup
						row
						aria-label='scholarshipFilter'
						name='scholarship-filter-row-radio-buttons-group'
						value={activeFilter}
						onChange={(e) => setActiveFilter(e.target.value)}
					>
						<FormControlLabel
							value='true'
							control={<Radio color='primary' />}
							label='Active'
						/>
						<FormControlLabel
							value='false'
							control={<Radio color='primary' />}
							label='Inactive'
						/>
					</RadioGroup>
				</FormControl>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{scolarships.length > 0 ? (
						<Table>
							<tbody>
								{scolarships.map((item, index) => (
									<tr key={index}>
										<td className='col-6'>
											<a
												href={item.link}
												rel='noreferrer'
												target='_blank'
											>
												{item.title}
											</a>
										</td>
										<td
											className='col-4'
											style={{ textAlign: 'right' }}
										>
											{item.status ? (
												<Button
													variant='info'
													size='sm'
													onClick={() =>
														updateScholarship(
															item._links.self
																.href,
															{
																status: false,
															}
														)
													}
												>
													&#10006; Deactivate
												</Button>
											) : (
												<Button
													variant='info'
													size='sm'
													onClick={() =>
														updateScholarship(
															item._links.self
																.href,
															{
																status: true,
															}
														)
													}
												>
													&#10004; Activate
												</Button>
											)}
											&nbsp;
											<Button
												variant='warning'
												size='sm'
												onClick={() => {
													setOnEdit(true)
													setCurrentScholarship(item)
													setShowAddScholarshipModal(
														true
													)
												}}
											>
												<EditFilled /> Edit
											</Button>
											&nbsp;
											<Button
												variant='danger'
												size='sm'
												onClick={() =>
													removeScholarship(
														item._links.self.href
													)
												}
											>
												<DeleteFilled /> Delete
											</Button>
										</td>
									</tr>
								))}
								{totalPages > 1 && (
									<Pagination
										name={'Scholarships'}
										currentPage={currentPage}
										totalPages={totalPages}
										setCurrentPage={setCurrentPage}
									/>
								)}
							</tbody>
						</Table>
					) : (
						<h4>No Scholarships Found!!!</h4>
					)}

					<Button
						variant='success'
						onClick={() => setShowAddScholarshipModal(true)}
						className='mt-3'
					>
						+ Add New Scholarship
					</Button>
					<AddScolarShips
						show={showAddScholarshipModal}
						modalClose={() => setShowAddScholarshipModal(false)}
						currentScholarship={currentScholarship}
						setCurrentScholarship={setCurrentScholarship}
						onEdit={onEdit}
						setOnEdit={setOnEdit}
						addScholarShip={addScholarship}
						updateScholarship={updateScholarship}
					/>
				</>
			)}
		</>
	)
}

const AddScolarShips = ({
	show,
	modalClose,
	currentScholarship,
	setCurrentScholarship,
	onEdit,
	setOnEdit,
	addScholarShip,
	updateScholarship,
}) => {
	const handleOnChange = (e) => {
		if (e.target.name === 'status') {
			setCurrentScholarship({
				...currentScholarship,
				[e.target.name]: e.target.checked,
			})
		} else {
			setCurrentScholarship({
				...currentScholarship,
				[e.target.name]: e.target.value,
			})
		}
	}

	const refreshModal = () => {
		modalClose()
		setCurrentScholarship({ title: '', link: '', status: false })
		setOnEdit(false)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (onEdit) {
			updateScholarship(
				currentScholarship._links.self.href,
				currentScholarship
			)
		} else {
			addScholarShip()
		}
		refreshModal()
	}

	return (
		<Modal
			show={show}
			onHide={refreshModal}
			aria-labelledby='contained-modal-title-vcenter'
			centered
			backdrop='static'
			keyboard={false}
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header>
					<Modal.Title id='contained-modal-title-vcenter'>
						{onEdit ? 'Edit Scholarship' : 'Add New Scholarship'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h5>Enter Scholarship Details</h5>
					<Form.Group>
						<Form.Label>Enter Name: </Form.Label>
						<Form.Control
							placeholder='scholarship name here'
							value={currentScholarship.title}
							name='title'
							onChange={handleOnChange}
							required
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Enter Link: </Form.Label>
						<Form.Control
							placeholder='http://www.xyz.com'
							value={currentScholarship.link}
							name='link'
							onChange={handleOnChange}
							required
						/>
					</Form.Group>
					{!onEdit && (
						<Form.Group>
							<Form.Check
								type='checkbox'
								label='check if scholarship is published'
								value={currentScholarship.status}
								name='status'
								onChange={handleOnChange}
							/>
						</Form.Group>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={refreshModal}>Close</Button>
					<Button type='submit' variant='success'>
						{onEdit ? 'Update' : 'Add'}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default ScolarAssists
