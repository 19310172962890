export const getEmailSettings = (params, callback) => {
	const request = {
		method: 'GET',
		url: '/emailSettings',
	}
	params.apiCallInterpreter(request, callback)
}

export const createEmailSettings = (params, callback) => {
	const request = {
		method: 'POST',
		url: '/emailSettings',
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const editEmailSettings = (params, callback) => {
	const request = {
		method: 'PATCH',
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}
