import React from "react";
import { Image } from "react-bootstrap";
import userPhoto from '../../../assets/user-placeholder.png';

function TeamMember(props) {
  const { image, altText, name, profession, education, desc, inspiration } = props;
  let imageUrl;
  if(!image){
    imageUrl=userPhoto;
  } else {
    imageUrl = image;
  }
  return (
    <>
      <div
        className="container-fluid m-auto"
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          fontSize: "16px",
        }}
      >
        <div className="row m-auto">
          <div className="col-lg-4 col-sm-12 p-3 text-center my-auto">
            <Image
              src={imageUrl}
              alt={altText}
              style={{height:150,width:150,objectFit: 'cover'}}
              roundedCircle
              fluid
            />
           </div>
          
          <div className="col-lg-8 col-sm-12 p-3">
            <span><strong>{name}</strong></span>
            <br />
            <span
              style={{
                color: "lightslategray",
                fontWeight: "lighter",
                fontSize: "12px",
              }}
            >
              {education}
            </span>

            <p style={{ color: "slategray" }}>{profession}</p>
               
            <p style={{ fontWeight: "lighter", color: "#324145",fontStyle:'italic',paddingBottom:0,marginBottom:0 }}>
              <i className="fa fa-quote-left" style={{fontSize: 8,color: 'grey',verticalAlign:'super'}}/>
                {desc}
               <i className="fa fa-quote-right" style={{fontSize: 8,color:'grey',verticalAlign:'super'}}/></p>
           
            {inspiration && 
            <p style={{ fontWeight: "lighter", color: "#324145",paddingTop:10}}><span style={{fontWeight: 900}}>What motivated to join Xomidhan:</span> {inspiration}</p>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default TeamMember;
