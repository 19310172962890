export const getCategories = (params, callback) => {
	const request = {
		method: "GET",
		url: `/categories?page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const addCategory = (params, callback) => {
	const request = {
		method: "POST",
		url: "/categories",
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const updateCategory = (params, callback) => {
	const request = {
		method: "PUT",
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const removeCategory = (params, callback) => {
	const request = {
		method: "DELETE",
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}
