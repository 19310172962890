import { useState, useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import * as coreTeamActions from './coreTeam.actions'
import { Loading } from '../Loading'
import { Button, Table, Image } from 'react-bootstrap'
import { MinusCircleOutlined } from '@ant-design/icons'
import userPhoto from '../../../../assets/user-placeholder.png'
import { Pagination } from '../Pagination'
import { itemsPerPage } from '../../../../utils/constant'

const CoreTeam = () => {
	const [coreTeamMembers, setCoreTeamMembers] = useState([])
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination

	const {
		isLoading,
		setCurrentTab,
		setIsLoading,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('coreteam')
	}, [])

	useEffect(() => {
		getCoreTeamMembers()
	}, [currentPage])

	const getCoreTeamMembers = () => {
		setIsLoading(true)
		coreTeamActions.getCoreTeamMembers(
			{ apiCallInterpreter, page: currentPage - 1, size: itemsPerPage },
			(res, err) => {
				if (!err) {
					setTotalPages(res.page.totalPages)
					setCoreTeamMembers(res._embedded.counsellors)
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went wrong',
						type: 'failure',
					})
				}
				setIsLoading(false)
			}
		)
	}

	const removeCoreTeamMember = (url) => {
		setIsLoading(true)
		coreTeamActions.putCoreTeamMembers(
			{
				url: `${url}/promote`,
				data: { isCoreTeamMember: false },
				apiCallInterpreter,
			},
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Core Team Member Removed Successfully',
							type: '',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				setIsLoading(false)
				getCoreTeamMembers()
			}
		)
	}

	return (
		<>
			<div className='titleBox mb-3'>
				<h2>Core Team</h2>
			</div>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{coreTeamMembers.length > 0 ? (
						<Table>
							<thead>
								<tr>
									<th>Name</th>
									<th>Educational Qualification</th>
									<th>Current Profession</th>
									<th>About</th>
									<th style={{ textAlign: 'right' }}>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{coreTeamMembers.map((item, index) => (
									<tr key={index}>
										<td style={{ minWidth: '200px' }}>
											<Image
												src={
													item.hasPhoto
														? `${item._links.self.href}/pic`
														: userPhoto
												}
												style={{
													height: 30,
													width: 30,
													objectFit: 'cover',
												}}
												roundedCircle
												fluid
											/>
											&nbsp;
											{item.name}
										</td>
										<td>{item.educationalQualification}</td>
										<td>{item.currentProfession}</td>
										<td>{item.aboutMe}</td>
										<td
											style={{
												textAlign: 'right',
												minWidth: '150px',
											}}
										>
											<Button
												variant='danger'
												size='sm'
												onClick={() =>
													removeCoreTeamMember(
														item._links.self.href
													)
												}
											>
												<MinusCircleOutlined /> &nbsp;
												Remove
											</Button>
										</td>
									</tr>
								))}
								{totalPages > 1 && (
									<Pagination
										name={'CoreTeamMembers'}
										currentPage={currentPage}
										totalPages={totalPages}
										setCurrentPage={setCurrentPage}
									/>
								)}
							</tbody>
						</Table>
					) : (
						<h4>No Members Found!!!</h4>
					)}
				</>
			)}
		</>
	)
}

export default CoreTeam
