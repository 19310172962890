import app from '../../../utils/apiCall'

export const getCategories = (params, callback) => {
	let request = {
		method: 'GET',
		url: '/categories?size=1000',
	}
	params.url !== undefined && (request.url = params.url)
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}

export const updateCounselor = (params, callback) => {
	let request = {
		method: 'PUT',
		url: params.url,
		data: params.data,
	}
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}

export const joinXomidhan = (params, callback) => {
	let request = {
		method: 'POST',
		url: `/counsellors?response=${params.token}`,
		data: params.data,
	}
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}

// export const getCounselor = (params, callback) => {
// 	let request = {
// 		method: "GET",
// 		url: `/counsellors/${params.id}`,
// 	}
// 	params.setLoading(true)
// 	app.invokeApi(request, (res, err) => {
// 		params.setLoading(false)
// 		callback(res, err)
// 	})
// }
