import app from '../../utils/apiCall'

export const addQuestion = (params, callback) => {
	const request = {
		method: 'POST',
		url: `/questions?response=${params.recaptchaToken}`,
		data: params.data,
	}
	app.invokeApi(request, callback)
}

export const updateQuestion = (params, callback) => {
	const request = {
		method: 'PUT',
		url: params.url,
		data: params.data,
	}
	app.invokeApi(request, callback)
}
