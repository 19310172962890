import React from 'react'
import JoinXomidhanForm from './JoinXomidhanForm/JoinXomidhanForm'
import {APP_ENVIRONMENT} from '../../utils/apiCall'
import { recaptcha_site_key, recaptcha_site_key_prod } from '../../utils/constant'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function JoinXomidhan() {
    let site_key=recaptcha_site_key;
    if(APP_ENVIRONMENT==='prod'){
        site_key=recaptcha_site_key_prod
    }
	return (
		<GoogleReCaptchaProvider reCaptchaKey={site_key}>
			<div className='container-fluid'>
				<div className='row bg-light'>
					<div className='col-lg-6 col-sm-12 paraContainer'>
						<p>
							Do you have a good understanding of your career
							domain ? Good enough to provide professional
							guidance to aspirants ? If yes, why don't you
							register yourself as a Career Counsellor with us ?
							Only when Xomidhan receives a query related to your
							specific area of expertise, you will be required to
							respond. You can even don the role of a Mentor and
							guide a Mentee in his/her career.
						</p>

						<div className='col-12 m-auto text-align-center p-5'>
							<h3>Be One of Us</h3>
						</div>
					</div>
					<div
						className='col-lg-6 col-sm-12 bg-light'
						style={{ backgroundColor: '#fff' }}
					>
						<JoinXomidhanForm />
					</div>
				</div>
			</div>
		</GoogleReCaptchaProvider>
	)
}
export default JoinXomidhan
