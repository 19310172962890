import { useState, useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'
import { Table, Button, Modal } from 'react-bootstrap'
import { Loading } from '../Loading'
import { AnswerForm } from '.'
import { EditFilled } from '@ant-design/icons'
import * as answersActions from './answers.actions'
import { Pagination } from '../Pagination'
import { itemsPerPage } from '../../../../utils/constant'

function Answer() {
	const [currentPage, setCurrentPage] = useState(1) // pagination
	const [totalPages, setTotalPages] = useState(1) // pagination
	const [questions, setQuestions] = useState([])
	const [currentQuestion, setCurrentQuestion] = useState({})
	const [isUpdateAnswerModalOn, setIsUpdateAnswerModalOn] = useState(false)
	const {
		isLoading,
		setIsLoading,
		setCurrentTab,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('answer')
		getQuestions()
	}, [currentPage])

	const getQuestions = () => {
		setIsLoading(true)
		answersActions.getQuestions(
			{ apiCallInterpreter, page: currentPage - 1, size: itemsPerPage },
			(res, err) => {
				if (!err) {
					setTotalPages(res.page.totalPages)
					let temp = []
					res._embedded.questions.map((item) => {
						answersActions.getAnswer(
							{
								url: item._links.answer.href,
								apiCallInterpreter,
							},
							(res, err) => {
								if (!err) {
									temp = [...temp, { ...item, answer: res }]
								} else {
									temp = [
										...temp,
										{
											...item,
											answer: {
												isPublic: false,
												answer: 'Unable to fetch answer. Please try again...',
											},
										},
									]
								}
								setQuestions([...temp])
							}
						)
					})
					setIsLoading(false)
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
					setIsLoading(false)
				}
			}
		)
	}

	const updateAnswer = (data) => {
		setIsLoading(true)
		answersActions.updateAnswer(
			{
				url: currentQuestion.answer._links.self.href,
				data,
				apiCallInterpreter,
			},
			(res, err) => {
				setIsLoading(false)
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: 'Answer Updated Successfully',
						type: 'success',
					})
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went Wrong!!! Try Again...',
						type: 'failure',
					})
				}
				getQuestions()
			}
		)
		setIsUpdateAnswerModalOn(false)
	}

	const handleUpdateAnswerButtonClick = (item) => {
		setCurrentQuestion(item)
		setIsUpdateAnswerModalOn(true)
	}

	return (
		<>
			{/* Edit Answer Modal */}
			<Modal
				show={isUpdateAnswerModalOn}
				onHide={() => setIsUpdateAnswerModalOn(false)}
				size='md'
				aria-labelledby='updateAnswerModal'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='updateAnswerModal'>
						Update Answer
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AnswerForm
						answer={currentQuestion.answer}
						updateAnswer={updateAnswer}
					/>
				</Modal.Body>
			</Modal>

			{/* Answers Page */}
			<div className='titleBox'>
				<h2 className='pb-3'>Answered Questions</h2>
			</div>
			{isLoading ? (
				<Loading />
			) : questions.length > 0 ? (
				<>
					<Table>
						<thead>
							<tr>
								<th>Question</th>
								<th>Answer</th>
								<th style={{ textAlign: 'center' }}>
									Public Answer
								</th>
								<th style={{ textAlign: 'right' }}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{questions.map((item, index) => (
								<tr key={index}>
									<td>{item.question}</td>
									<td>{item.answer.answer}</td>
									<td style={{ textAlign: 'center' }}>
										{item.answer.isPublic ? 'Yes' : 'No'}
									</td>
									<td style={{ textAlign: 'right' }}>
										<Button
											size='sm'
											variant='warning'
											onClick={() =>
												handleUpdateAnswerButtonClick(
													item
												)
											}
										>
											<EditFilled /> &nbsp; Update Answer
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					{totalPages > 1 && (
						<Pagination
							name={'Answers'}
							currentPage={currentPage}
							totalPages={totalPages}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</>
			) : (
				<h5>No questions available</h5>
			)}
		</>
	)
}
export default Answer
