import app from "../../utils/apiCall"

export const getOtp = (params, callback) => {
	const request = {
		method: "POST",
		url: "/otps/profile",
		data: params.data,
	}
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}

export const verifyOtp = (params, callback) => {
	const request = {
		method: "POST",
		url: "/otps/verifyOtp",
		data: params.data,
	}
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}
