import { useState, useContext } from "react"
import { AdminContext } from "../../XomidhanAdmin"
import { MyToast } from "../../../MyToast"
import { login } from "./login.actions"
import { storeData } from "../../../../utils/asyncData"
import logo from "../../../../assets/x-logo.png"
import styles from "../../xomidhanAdmin.module.css"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"

const Login = () => {
	const [user, setUser] = useState("")
	const [pass, setPass] = useState("")
	const [toast, setToast] = useState({
		message: "",
		visible: false,
		timeOut: 3000,
		type: "success",
	}) // Alert
	const { isLoading, setIsLoading, setIsLoggedIn, setAuthResponse } =
		useContext(AdminContext)

	// function to dismiss toast
	const hideToast = () => {
		setToast({ ...toast, visible: false })
	}

	const handleSubmit = e => {
		e.preventDefault()
		setIsLoading(true)
		storeData("adminTab", "", status => {
			status
				? console.log("Admin initialized with dashboard")
				: console.log("something went wrong!")
			login({ user, pass }, (status, res) => {
				setIsLoading(false)
				if (status) {
					setAuthResponse(res)
					setIsLoggedIn(true)
				} else {
					setToast({
						...toast,
						visible: true,
						message: "Login Failed... Check Username and Password again...",
						type: "failure",
					})
					setIsLoggedIn(false)
				}
			})
		})
	}

	return (
		<div className={styles["main-div"]}>
			<MyToast
				message={toast.message}
				visible={toast.visible}
				type={toast.type}
				timeOut={toast.timeOut}
				hideToast={hideToast}
			/>
			<div className={styles["container-div"]}>
				{/* heading */}
				<span>
					<img src={logo} alt="logo" height="40px" width="auto" />
				</span>
				<h1 className={styles["title"]}>Login</h1>
				<div className={styles["title-underline"]}></div>

				{/* login form */}
				<form onSubmit={handleSubmit}>
					<input
						type="text"
						name="username"
						placeholder="username"
						value={user}
						onChange={e => setUser(e.target.value)}
						className={styles["form-input"]}
						required
					/>
					<br />
					<input
						type="password"
						name="password"
						placeholder="password"
						value={pass}
						onChange={e => setPass(e.target.value)}
						className={styles["form-input"]}
						required
					/>
					<br />
					{isLoading ? (
						<Button block variant="info" disabled>
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
							<span className="sr-only">Loading...</span>
						</Button>
					) : (
						<Button
							as="input"
							variant="info"
							type="submit"
							value="Log In"
							block
						/>
					)}
				</form>
			</div>
		</div>
	)
}

export default Login
