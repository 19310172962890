import app from '../../utils/apiCall'

export const getCategories = (params, callback) => {
	let request = {
		method: 'GET',
		url: '/categories?size=1000&sort=name,asc',
	}
	params.setLoading(true)
	app.invokeApi(request, (res, err) => {
		params.setLoading(false)
		callback(res, err)
	})
}
