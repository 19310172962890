import { useState, useEffect, useCallback, useRef } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import styles from './joinXomidhanForm.module.css'
import {
	getCategories,
	updateCounselor,
	joinXomidhan,
} from './joinXomidhan.actions'
import { TextError } from '../JoinXomidhanForm'
import { MyToast } from '../../MyToast'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const validationSchema = Yup.object().shape({
	name: Yup.string().required().min(3).label('Name'),
	email: Yup.string().email().required().label('E-mail'),
	phone: Yup.number('Enter valid number')
		.typeError('Not a valid number')
		.optional()
		.max(+999999999999, 'Number can not contain more than 10 digits')
		.label('Phone'),
	currentLocation: Yup.string().required().min(3).label('Current Location'),
	educationalQualification: Yup.string()
		.required()
		.min(2)
		.label('Educational Qualification'),
	currentProfession: Yup.string()
		.required()
		.min(4)
		.label('Current Profession'),
	categories: Yup.array().min(1).required().label('Categories'),
	aboutMe: Yup.string().required().label('Introduction'),
})

const JoinXomidhanForm = ({
	counselor,
	otp,
	setEmailEntered,
	onEdit,
	setOnEdit,
	getCounselors,
}) => {
	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		phone: '',
		currentLocation: '',
		educationalQualification: '',
		currentProfession: '',
		categories: [],
		aboutMe: '',
		myInspiration: '',
		file: null,
		photo: null,
	})
	const { executeRecaptcha } = useGoogleReCaptcha()
	const captchaRef = useRef()
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(false)
	const [toast, setToast] = useState({
		message: '',
		visible: false,
		timeOut: 5000,
		type: 'success',
	})

	useEffect(() => {
		getCategories({ setLoading }, (res, err) => {
			if (!err) {
				setCategories(res._embedded.categories)
			} else {
				console.log('Unable to fetch categories')
			}
		})
		if (counselor) {
			let temp = { ...counselor }
			temp.phone = temp.phone !== null ? temp.phone.number : ''
			temp.myInspiration =
				temp.myInspiration !== null ? temp.myInspiration : ''
			getCategories(
				{ setLoading, url: counselor._links.categories.href },
				(res, err) => {
					if (!err) {
						temp.categories = [
							...res._embedded.categories.map(
								(item) => item._links.self.href
							),
						]
						setInitialValues(temp)
					} else {
						console.log('Unable to fetch categories')
					}
				}
			)
		}
	}, [])

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log('recaptcha not yet available')
			return ''
		}
		const token = await executeRecaptcha('counsellor')
		return token
	}, [executeRecaptcha])

	useEffect(() => {
		handleReCaptchaVerify()
			.then((token) => {
				captchaRef.current = token
			})
			.catch((err) => console.error('something went wrong', err))
	}, [handleReCaptchaVerify])

	const hideToast = () => {
		setToast({ ...toast, visible: false, message: '' })
	}

	const renderCategories = () => {
		return categories.length ? (
			categories.map((cat) => (
				<option key={cat.name} value={cat._links.self.href}>
					{cat.name}
				</option>
			))
		) : (
			<option key='error_loading'>Unable to fetch categories</option>
		)
	}

	const extractBlog = (file, setFieldValue) => {
		let reader = new FileReader()
		// let file = new File(f);
		console.log(file)
		/*if(file.size>500000){
        //toastr.error("Image size should be less than 500KB");
        this.showModal();
        e.target.value="";
        } else if(file.type!='image/png' && file.type!='image/jpeg'){
        this.showModal2();
        e.target.value="";
        } else {*/
		if (file.type.match(/image.*/)) {
			reader.onloadend = (readerEvent) => {
				console.log('on load end')
				var image = new Image()
				image.onload = function (imageEvent) {
					// Resize the image
					var canvas = document.createElement('canvas'),
						max_size = 800, // TODO : pull max size from a site config
						width = image.width,
						height = image.height
					if (width > height) {
						if (width > max_size) {
							height *= max_size / width
							width = max_size
						}
					} else {
						if (height > max_size) {
							width *= max_size / height
							height = max_size
						}
					}
					canvas.width = width
					canvas.height = height
					canvas
						.getContext('2d')
						.drawImage(image, 0, 0, width, height)
					var dataUrl = canvas.toDataURL('image/jpeg')
					var resizedImage = dataURLToBlob(dataUrl)
					console.log(resizedImage)
					console.log(dataUrl)
					setFieldValue('photo', {
						fileName: file.name,
						base64string: dataUrl,
					})
				}
				image.src = readerEvent.target.result
			}
			reader.readAsDataURL(file)
		}
	}

	const dataURLToBlob = (dataURL) => {
		var BASE64_MARKER = ';base64,'
		if (dataURL.indexOf(BASE64_MARKER) === -1) {
			var parts = dataURL.split(',')
			var contentType = parts[0].split(':')[1]
			var raw = parts[1]
			return new Blob([raw], { type: contentType })
		}

		parts = dataURL.split(BASE64_MARKER)
		contentType = parts[0].split(':')[1]
		raw = window.atob(parts[1])
		var rawLength = raw.length
		var uInt8Array = new Uint8Array(rawLength)

		for (var i = 0; i < rawLength; ++i) {
			uInt8Array[i] = raw.charCodeAt(i)
		}
		return new Blob([uInt8Array], { type: contentType })
	}

	const submitForm = (values, { resetForm }) => {
		values.phone === ''
			? delete values.phone
			: (values.phone = { number: values.phone })

		//values.myInspiration === "" && delete values.myInspiration

		delete values._links
		delete values.createdAt
		delete values.modifiedAt
		console.log('Before upload', values)
		if (counselor === undefined) {
			joinXomidhan(
				{ data: values, setLoading, token: captchaRef.current },
				(res, err) => {
					if (!err) {
						setToast({
							...toast,
							message:
								'Successfully submitted. Your counsellorship is subject to approval.',
							visible: true,
							type: 'success',
						})
						resetForm({
							values: {
								name: '',
								email: '',
								phone: '',
								currentLocation: '',
								educationalQualification: '',
								currentProfession: '',
								categories: [],
								aboutMe: '',
								myInspiration: '',
								file: null,
								photo: null,
							},
						})
						window.location.reload()
					} else {
						console.log(res, err)
						let message = ''
						if (
							res?.errors &&
							Object.keys(res?.errors).length > 0
						) {
							message = Object.keys(res.errors)
								.map((m) => res.errors[m])
								.join('<br />')
						} else if (res?.errorCode === 3002) {
							message =
								'Counsellor with the email id already exist.'
						} else {
							message = 'Something went wrong'
						}
						setToast({
							...toast,
							message: message,
							visible: true,
							type: 'failure',
						})
					}
				}
			)
		} else {
			updateCounselor(
				{
					data: otp ? { ...values, otp } : values,
					setLoading,
					url: onEdit
						? `${counselor._links.self.href}/adminAccess`
						: counselor._links.self.href,
				},
				(res, err) => {
					if (!err) {
						console.log('Successfully Updated', res)
						setToast({
							...toast,
							message:
								'Successfully updated. Your counsellorship is now subject to approval.',
							visible: true,
							type: 'success',
						})
						resetForm({
							values: {
								name: '',
								email: '',
								phone: '',
								currentLocation: '',
								educationalQualification: '',
								currentProfession: '',
								categories: [],
								aboutMe: '',
								myInspiration: '',
								file: null,
								photo: null,
							},
						})
					} else {
						console.log('Error!!!', err)
						setToast({
							...toast,
							message: 'Something went wrong!!!',
							visible: true,
							type: 'failure',
						})
					}
					setTimeout(() => {
						getCounselors !== undefined && getCounselors()
						setEmailEntered !== undefined && setEmailEntered(false)
						setOnEdit !== undefined && setOnEdit(false)
					}, 2000)
				}
			)
		}
	}

	return (
		<>
			<div className='container-fluid'>
				{counselor === undefined && (
					<div
						className={styles.titleHolder}
						style={{ textAlign: 'center', paddingTop: '50px' }}
					>
						<div className='titleBox'>
							<h3>Register</h3>
						</div>
					</div>
				)}

				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submitForm}
				>
					{(props) => {
						return (
							<div
								className='container-fluid pb-5'
								style={{ alignItems: 'center' }}
							>
								<Form
									onSubmit={props.handleSubmit}
									className='g-6 justify-content-center align-items-center'
								>
									<MyToast
										message={toast.message}
										visible={toast.visible}
										type={toast.type}
										timeOut={toast.timeOut}
										hideToast={hideToast}
									/>
									<div className='row'>
										<div className='col-12 form-group p-2 '>
											<label htmlFor='name'>Name</label>
											<Field
												width='100%'
												type='text'
												id='name'
												name='name'
												placeholder='Name'
												className='form-control'
											/>
											<ErrorMessage
												name='name'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group p-2'>
											<label htmlFor='email'>Email</label>
											<Field
												type='email'
												className='form-control'
												id='email'
												name='email'
												placeholder='name@example.com'
												disabled={
													counselor === undefined
														? false
														: true
												}
											/>
											<ErrorMessage
												name='email'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group p-2'>
											<label htmlFor='phone'>Phone</label>
											<Field
												className='form-control'
												id='phone'
												name='phone'
												placeholder='Phone Number(optional)'
											/>
											<ErrorMessage
												name='phone'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group p-2'>
											<label htmlFor='currentLocation'>
												Current Location
											</label>
											<Field
												type='text'
												className='form-control'
												id='currentLocation'
												name='currentLocation'
												placeholder='Current Location'
											/>
											<ErrorMessage
												name='currentLocation'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group  p-2'>
											<label htmlFor='educationalQualification'>
												Educational Qualification
											</label>
											<Field
												type='text'
												className='form-control'
												id='educationalQualification'
												name='educationalQualification'
												placeholder='Educational Qualification'
											/>
											<ErrorMessage
												name='educationalQualification'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group p-2'>
											<label htmlFor='currentProfession'>
												Current profession
											</label>
											<Field
												type='text'
												className='form-control'
												id='currentProfession'
												name='currentProfession'
												placeholder='Current profession'
											/>
											<ErrorMessage
												name='currentProfession'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group p-2'>
											<label htmlFor='categories'>
												Subject in which you want to
												provide counselling(Please use
												'ctrl' key to select more than
												one category)
											</label>

											<Field
												as='select'
												multiple={true}
												type='text'
												className='form-control custom-select'
												id='categories'
												name='categories'
												// defaultValue={counselorCategories.map(item => item._links.self.href)}
											>
												{loading ? (
													<option
														key='loading'
														value='loading'
													>
														Loading...
													</option>
												) : (
													renderCategories()
												)}
											</Field>

											<ErrorMessage
												name='categories'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group  p-2'>
											<label htmlFor='aboutMe'>
												Introduction
											</label>
											<Field
												as='textarea'
												rows={5}
												style={{ resize: 'none' }}
												className='form-control'
												id='aboutMe'
												name='aboutMe'
												maxLength='2500'
												placeholder='Tell us about yourself(Please provide your educational and professional background, recognition/achievement, key skillset, future plans etc in maximun 2500 character)'
											/>
											<ErrorMessage
												name='aboutMe'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 form-group  p-2'>
											<label htmlFor='myInspiration'>
												My Inspiration
											</label>
											<Field
												as='textarea'
												rows={5}
												style={{ resize: 'none' }}
												className='form-control'
												id='myInspiration'
												name='myInspiration'
												maxLength='2500'
												// onChange={handleChange}
												placeholder='What inspired you to volunteer for Xomidhan? (optional)'
											/>
											<ErrorMessage
												name='myInspiration'
												component={TextError}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='input-group mb-3 col-12 p-2'>
											<div className='form-group'>
												<label htmlFor='file'>
													Upload photo
												</label>
												<input
													id='file'
													name='file'
													type='file'
													onChange={(event) => {
														props.setFieldValue(
															'file',
															event.currentTarget
																.files[0]
														)
														// this.setFile(event.currentTarget.files[0]);
														// console.log(extractBlog);
														let photo = extractBlog(
															event.currentTarget
																.files[0],
															props.setFieldValue
														)
														console.log(
															'photo====>',
															photo
														)
													}}
													className='form-control'
												/>
												<input
													type='hidden'
													name='photo'
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 p-2'>
											{loading ? (
												<button disabled>
													<Spinner
														animation='border'
														variant='success'
														size='sm'
													/>
												</button>
											) : (
												<button
													type='submit'
													className='btn'
													style={{
														backgroundColor:
															'#1687a7',
														color: '#fff',
													}}
												>
													Submit
												</button>
											)}
										</div>
									</div>
								</Form>
							</div>
						)
					}}
				</Formik>
			</div>
		</>
	)
}

export default JoinXomidhanForm

// old code, may require someday...

// const initialValues = {
// 	name: "",
// 	email: "",
// 	phone: "",
// 	currentLocation: "",
// 	educationalQualification: "",
// 	currentProfession: "",
// 	categories: [],
// 	aboutMe: "",
// 	myInspiration: "",
// 	file: null,
// 	photo: null,
// }

// const handleImageChange = (e, values) => {
// 	e.preventDefault()
// 	var self = this
// 	let reader = new FileReader()
// 	let file = e.target.files[0]
// 	if (file.type.match(/image.*/)) {
// 		reader.onloadend = readerEvent => {
// 			var image = new Image()
// 			image.onload = function (imageEvent) {
// 				// Resize the image
// 				var canvas = document.createElement("canvas"),
// 					max_size = 800, // TODO : pull max size from a site config
// 					width = image.width,
// 					height = image.height
// 				if (width > height) {
// 					if (width > max_size) {
// 						height *= max_size / width
// 						width = max_size
// 					}
// 				} else {
// 					if (height > max_size) {
// 						width *= max_size / height
// 						height = max_size
// 					}
// 				}
// 				canvas.width = width
// 				canvas.height = height
// 				canvas.getContext("2d").drawImage(image, 0, 0, width, height)
// 				var dataUrl = canvas.toDataURL("image/jpeg")
// 				var resizedImage = dataURLToBlob(dataUrl)
// 				console.log(resizedImage)
// 				console.log(dataUrl)

// 				self.setState({
// 					file: file,
// 					imagePreviewUrl: dataUrl,
// 					photo: { fileName: file.name, base64string: dataUrl },
// 					imagePreviewClassName: "imagePreview",
// 				})
// 				values.photo = { fileName: file.name, base64string: dataUrl }
// 				values.file = file
// 			}
// 			image.src = readerEvent.target.result
// 		}
// 		reader.readAsDataURL(file)
// 	} else {
// 		this.showModal2()
// 		e.target.value = ""
// 	}
// }
