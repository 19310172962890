import { useState, useContext } from "react"
import { AdminContext } from "../../XomidhanAdmin"
import { JoinXomidhanForm } from "../../../JoinXomidhan/JoinXomidhanForm"
import { getCategories } from "./counselors.actions"
import {
	Button,
	ButtonGroup,
	Table,
	Dropdown,
	Image,
	Modal,
} from "react-bootstrap"
import {
	EditFilled,
	LikeFilled,
	DislikeFilled,
	DeleteFilled,
	PlusCircleOutlined,
	MinusCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons"
import userPhoto from "../../../../assets/user-placeholder.png"
import styles from "../../xomidhanAdmin.module.css"

const CounselorList = ({
	counselors,
	pageStatus,
	getCounselors,
	approveCounselor,
	rejectCounselor,
	removeCounselor,
	manageToCoreTeamMembers,
}) => {
	const [onEdit, setOnEdit] = useState(false)
	const [editCounselor, setEditCounselor] = useState({})
	const [onReject, setOnReject] = useState(false)
	const [rejectReason, setRejectReason] = useState({ url: "", reason: "" })
	const [showPopover, setShowPopover] = useState(false)
	const [counselorCategories, setCounselorCategories] = useState([])
	const { apiCallInterpreter } = useContext(AdminContext)

	const closeModal = () => {
		setOnEdit(false)
		setEditCounselor(null)
	}

	const handleRejectionSubmit = e => {
		e.preventDefault()
		rejectCounselor(rejectReason.url, rejectReason.reason)
		setRejectReason({ url: "", reason: "" })
		setOnReject(false)
	}

	const handlePopover = url => {
		getCategories({ url, apiCallInterpreter }, (res, err) => {
			!err
				? setCounselorCategories([
						...res._embedded.categories.map(item => item.name),
				  ])
				: setCounselorCategories(["Something went wrong. Reload the page"])

			setShowPopover(true)
		})
	}

	return (
		<>
			{/* Edit counselor modal */}
			<Modal
				show={onEdit}
				onHide={closeModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Edit Counselor
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<JoinXomidhanForm
						counselor={editCounselor}
						onEdit={onEdit}
						setOnEdit={setOnEdit}
						getCounselors={getCounselors}
					/>
				</Modal.Body>
			</Modal>

			{/* Show counselor's categories modal */}
			<Modal
				show={showPopover}
				onHide={() => setShowPopover(false)}
				size="sm"
				aria-labelledby="counselor-categories"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="counselor-categories">Categories</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
						{counselorCategories.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</Modal.Body>
			</Modal>

			{/* Reject counselor modal */}
			<Modal
				show={onReject}
				onHide={() => {
					setRejectReason({ url: "", reason: "" })
					setOnReject(false)
				}}
				size="sm"
				aria-labelledby="rejectModal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="rejectModal">Reject Counselor</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleRejectionSubmit}>
						<label htmlFor="reason">Enter Reason for Rejection: </label>
						<input
							type="text"
							name="reason"
							value={rejectReason.reason}
							onChange={e =>
								setRejectReason({ ...rejectReason, reason: e.target.value })
							}
							required
						/>
						<br />
						<Button type="submit" variant="success" className="mt-3">
							Submit
						</Button>
					</form>
				</Modal.Body>
			</Modal>

			{/* Counselors list */}
			{counselors.length ? (
				<div style={{ minHeight: "80vh" }}>
					<Table className="table-hover">
						<thead>
							<tr>
								<th>Name</th>
								<th>Qualification</th>
								<th>Location</th>
								<th>Profession</th>
								<th>About</th>
								<th>Inspiration</th>
								<th>Categories</th>
								<th
									className={styles["flex-end-div"]}
									style={{ width: "150px" }}
								>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{counselors.map((item, index) => (
								<tr key={index}>
									<td style={{ minWidth: "200px" }}>
										<Image
											src={
												item.hasPhoto
													? `${item._links.self.href}/pic`
													: userPhoto
											}
											style={{ height: 30, width: 30, objectFit: "cover" }}
											roundedCircle
											fluid
										/>
										&nbsp;{item.name}
									</td>
									<td>{item.educationalQualification}</td>
									<td>{item.currentLocation}</td>
									<td>{item.currentProfession}</td>
									<td>{item.aboutMe}</td>
									<td>{item.myInspiration || "Not Provided"}</td>
									<td>
										<Button
											onClick={() => handlePopover(item._links.categories.href)}
											variant="link"
										>
											<ExclamationCircleOutlined />
											&nbsp;Click
										</Button>
									</td>
									<td
										className={styles["flex-end-div"]}
										style={{ width: "150px" }}
									>
										<Dropdown as={ButtonGroup} size="sm">
											<Button
												variant="warning"
												onClick={() => {
													setEditCounselor(item)
													setOnEdit(true)
												}}
											>
												<EditFilled />
												Edit
											</Button>

											<Dropdown.Toggle split variant="warning" />

											<Dropdown.Menu className="p-0">
												{(pageStatus === "pending" ||
													pageStatus === "rejected") && (
													<Dropdown.Item
														className="bg-success text-white"
														onClick={() =>
															approveCounselor(item._links.self.href)
														}
													>
														<LikeFilled /> &nbsp; Approve
													</Dropdown.Item>
												)}

												{(pageStatus === "pending" ||
													pageStatus === "approved") && (
													<Dropdown.Item
														className="bg-danger"
														onClick={() => {
															setRejectReason({
																...rejectReason,
																url: item._links.self.href,
															})
															setOnReject(true)
														}}
													>
														<DislikeFilled /> &nbsp; Reject
													</Dropdown.Item>
												)}

												{pageStatus === "approved" &&
													(item.isCoreTeamMember ? (
														<Dropdown.Item
															className="bg-info text-white"
															onClick={() =>
																manageToCoreTeamMembers(
																	item._links.self.href,
																	false
																)
															}
														>
															<MinusCircleOutlined /> &nbsp; Remove from
															Core-Team
														</Dropdown.Item>
													) : (
														<Dropdown.Item
															className="bg-info text-white"
															onClick={() =>
																manageToCoreTeamMembers(
																	item._links.self.href,
																	true
																)
															}
														>
															<PlusCircleOutlined /> &nbsp; Add to Core-Team
														</Dropdown.Item>
													))}
												{pageStatus !== "pending" && (
													<Dropdown.Item
														className="bg-danger"
														onClick={() =>
															removeCounselor(item._links.self.href)
														}
													>
														<DeleteFilled /> &nbsp; Remove
													</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			) : (
				<h4 className="mt-4 ml-2">No Counselor Found!!!</h4>
			)}
		</>
	)
}

export default CounselorList
