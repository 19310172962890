import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import styles from './home.module.css'

function Home() {
	const [searchBy, setSearchBy] = useState('')
	const history = useHistory()

	const handleSearchChange = (e) => {
		setSearchBy(e.target.value)
	}

	const handleSearch = () => {
		if (!!searchBy) {
			history.push(`/search/query=${searchBy}`)
		}
	}

	const handleSearchOnEnter = (e) => {
		if (e.key === 'Enter' && !!searchBy) {
			handleSearch()
		}
	}

	return (
		<>
			<div
				className='container-fluid h-100 bg-light'
				style={{
					backgroundColor: '#ffffff',
					margin: 'auto',
					padding: '40px',
					lineHeight: '2rem',
					maxWidth: '100vw',
				}}
			>
				<div className='titleBox'>
					<h2>What we do</h2>
				</div>
				<div style={{ padding: '25px 0 0 25px ' }}>
					<p
						style={{
							textAlign: 'justify',
							color: '#555',
						}}
					>
						<strong>Xomidhan</strong> (as per the Assamese
						dictionary) means uttar, an answer, a reply. As such,
						our mission is to provide a solution to the issues at
						hand. It could be a very small step towards solving the
						bigger problems that plague our state. But we want to
						make an effort, begin somewhere.
					</p>
				</div>

				<hr className='my-4' />

				<Grid container justify='center'>
					<Grid item xs={12} md={10}>
						<TextField
							variant='outlined'
							size='small'
							fullWidth
							placeholder='search questions'
							InputLabelProps={{ shrink: true }}
							value={searchBy}
							onChange={handleSearchChange}
							onKeyDown={handleSearchOnEnter}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position='end'
										style={{ gap: '0.75rem' }}
									>
										{!!searchBy ? (
											<IconButton
												onClick={() => setSearchBy('')}
												aria-label='custom-search-bar-search-icon-button'
												edge='end'
											>
												<CloseOutlined
													style={{ fontSize: '14px' }}
												/>
											</IconButton>
										) : null}
										<Button
											variant='text'
											style={{
												textTransform: 'none',
												color: '#0087ff',
											}}
											onClick={handleSearch}
										>
											Search
										</Button>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>

				<hr className='my-4' />

				<NavLink className={styles.navLink} to='/about'>
					<div className={styles.learnMore}>Learn more</div>
				</NavLink>
			</div>
		</>
	)
}

export default Home
