import React, { useEffect, useContext } from 'react'
import { AdminContext } from '../../XomidhanAdmin'

function Dashboard() {
	const { setCurrentTab } = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('')
	}, [])

	return (
		<div className='titleBox'>
			<h2>Dashboard</h2>
		</div>
	)
}
export default Dashboard
