import React, { useEffect, useContext, useState } from 'react'
import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Select,
	MenuItem,
	Typography,
	InputLabel,
	FormControl
} from '@material-ui/core'
import { AdminContext } from '../../XomidhanAdmin'
import * as emailSettingsActions from './email.actions'
import { Loading } from '../Loading'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

const initialState = {
	emailId: '',
	password: '',
	host: '',
	port: '',
	secureLayer: ''
}

const Email = () => {
	const [emailInput, setEmailInput] = useState(initialState)
	const [showPassword, setShowPassword] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [url, setUrl] = useState('')
	const {
		isLoading,
		setCurrentTab,
		setIsLoading,
		toast,
		setToast,
		apiCallInterpreter,
	} = useContext(AdminContext)

	useEffect(() => {
		setCurrentTab('email')
		getEmailSettings();
		//getEmailSecureLayers();
	}, [])

	const handleChange = (e) => {
		setEmailInput({ ...emailInput, [e.target.name]: e.target.value })
		setDisabled(false)
	}

	const getEmailSettings = () => {
		setIsLoading(true)
		emailSettingsActions.getEmailSettings(
			{ apiCallInterpreter },
			(res, err) => {
				if (!err) {
					if (res._embedded.emailSettings.length > 0) {
						setEmailInput(
							Object.fromEntries(
								Object.keys(initialState).map((key) => [
									key,
									res._embedded.emailSettings.at(0)[key],
								])
							)
						)
						setUrl(
							res._embedded.emailSettings.at(0)._links.self.href
						)
					}
				} else {
					setToast({
						...toast,
						visible: true,
						message: 'Something went wrong',
						type: 'failure',
					})
				}
				setIsLoading(false)
			}
		)
	}

	const createEmailSettings = () => {
		setIsLoading(true)
		emailSettingsActions.createEmailSettings(
			{ data: emailInput, apiCallInterpreter },
			(res, err) => {
				!err
					? setToast({
							...toast,
							visible: true,
							message: 'Email Settings Created',
							type: 'success',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Something went wrong',
							type: 'failure',
					  })
				setIsLoading(false)
			}
		)
		getEmailSettings()
	}

	const updateEmailSettings = () => {
		setIsLoading(true)
		console.log('here2')
		emailSettingsActions.editEmailSettings(
			{ url, data: emailInput, apiCallInterpreter },
			(res, err) => {
				console.log('here3')
				err
					? setToast({
							...toast,
							visible: true,
							message: 'Something went wrong!!!',
							type: 'failure',
					  })
					: setToast({
							...toast,
							visible: true,
							message: 'Email Settings Updated',
							type: '',
					  })
				setIsLoading(false)
			}
		)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!url) {
			createEmailSettings()
		} else {
			console.log('here')
			updateEmailSettings()
		}
		setDisabled(true)
	}

	return (
		<Grid container>
			<Grid item xs={12} className='titleBox mb-5'>
				<h2 style={{ marginBottom: '0' }}>Email</h2>
				<Typography variant='subtitle2'>
					update email settings
				</Typography>
			</Grid>
			{isLoading ? (
				<Loading />
			) : (
				<Grid container item xs={12} justify='center'>
					<form onSubmit={handleSubmit}>
						<Grid container item xs={12} justify='center'>
							<Grid item xs={8} className='p-2'>
								<TextField
									variant='outlined'
									size='small'
									fullWidth
									type='email'
									label='Email ID'
									name='emailId'
									placeholder='email id'
									InputLabelProps={{ shrink: true }}
									value={emailInput.emailId}
									onChange={handleChange}
									required
								/>
							</Grid>
							<Grid item xs={8} className='p-2'>
								<TextField
									variant='outlined'
									size='small'
									fullWidth
									type={showPassword ? 'text' : 'password'}
									label='Password'
									name='password'
									placeholder='password'
									InputLabelProps={{ shrink: true }}
									value={emailInput.password}
									onChange={handleChange}
									required
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													color='primary'
													onClick={() =>
														setShowPassword(
															!showPassword
														)
													}
												>
													{showPassword ? (
														<EyeInvisibleOutlined />
													) : (
														<EyeOutlined />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={8} className='p-2'>
								<TextField
									variant='outlined'
									size='small'
									fullWidth
									label='Host'
									name='host'
									placeholder='host'
									InputLabelProps={{ shrink: true }}
									value={emailInput.host}
									onChange={handleChange}
									required
								/>
							</Grid>
							<Grid item xs={8} className='p-2'>
								<TextField
									variant='outlined'
									size='small'
									type='number'
									fullWidth
									label='Port'
									name='port'
									placeholder='port'
									InputLabelProps={{ shrink: true }}
									value={emailInput.port}
									onChange={handleChange}
									required
								/>
							</Grid>
							<Grid item xs={8} className='p-2'>
                                 <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-secureLayer-native-simple">SSL/TLS</InputLabel>
                                        <Select

                                          native
                                          name="secureLayer"
                                          value={emailInput.secureLayer}
                                          onChange={handleChange}
                                          label="SSL/TLS"
                                          inputProps={{
                                            name: 'secureLayer',
                                            id: 'outlined-secureLayer-native-simple',
                                          }}
                                        >
                                          <option aria-label="Select" value="" />
                                          <option value={'SSL'}>SSL</option>
                                          <option value={'TLS'}>TLS</option>

                                        </Select>
                                      </FormControl>


                            </Grid>
							<Grid
								item
								xs={8}
								container
								justify='center'
								className='p-2'
							>
								<Button
									variant='contained'
									type='submit'
									disabled={disabled}
									color='primary'
									fullWidth
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			)}
		</Grid>
	)
}

export default Email
