import { useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

const AnswerForm = ({ answer, updateAnswer, submitAnswer }) => {
	const [answerValue, setAnswerValue] = useState("")
	const [isPublicValue, setIsPublicValue] = useState(false)

	useEffect(() => {
		if (answer) {
			setAnswerValue(answer.answer)
			setIsPublicValue(answer.isPublic)
		}
	}, [])

	const handleSubmit = e => {
		e.preventDefault()
		const data = {
			isPublic: isPublicValue,
			answer: answerValue,
		}
		if (answer) {
			updateAnswer(data)
		} else {
			submitAnswer(data)
		}
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group>
				<Form.Check
					type="checkbox"
					label="keep it public"
					checked={isPublicValue}
					name="status"
					onChange={e => setIsPublicValue(e.target.checked)}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Answer</Form.Label>
				<Form.Control
					as="textarea"
					rows={5}
					name="answer"
					value={answerValue}
					onChange={e => setAnswerValue(e.target.value)}
					placeholder="type your answer here"
				/>
			</Form.Group>
			<Button variant="primary" type="submit">
				Submit
			</Button>
		</Form>
	)
}

export default AnswerForm
