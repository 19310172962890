import React, { useContext } from 'react'
import { AdminContext } from '../XomidhanAdmin'
import { NavLink } from 'react-router-dom'
import {
	HomeOutlined,
	LogoutOutlined,
	BookOutlined,
	UnorderedListOutlined,
	UserOutlined,
	QuestionCircleOutlined,
	CheckSquareOutlined,
	TeamOutlined,
	MailOutlined,
} from '@ant-design/icons'
import logo from '../../../assets/x-logo-white.png'

function AdminSideBar() {
	const { logout } = useContext(AdminContext)

	return (
		<div className='container-fluid p-0'>
			<nav className='navbar navbar-expand-lg navbar-dark p-0 admin-nav'>
				<div className='navbar-toggler admin-navbar-toggler'>
					<div className='row'>
						<div>
							<button
								type='button'
								data-toggle='collapse'
								data-target='#navbarSupportedContent'
								aria-controls='navbarSupportedContent'
								aria-expanded='false'
								aria-label='Toggle navigation'
								className='btn'
							>
								<span className='navbar-toggler-icon'></span>
							</button>
							<img
								src={logo}
								alt='logo'
								height='40px'
								width='auto'
							/>
						</div>
						<button
							className='btn btn-outline-warning btn-sm font-weight-bold ml-3 mr-2'
							onClick={logout}
						>
							<LogoutOutlined /> &nbsp; Logout
						</button>
					</div>
				</div>

				<div
					className='collapse navbar-collapse p-0 admin-side-bar-holder '
					id='navbarSupportedContent'
					style={{ width: '100vw' }}
				>
					<ul
						className='navbar-nav mr-auto admin-navbar-ul'
						style={{ listStyle: 'none', flexDirection: 'column' }}
					>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin'
								activeClassName='active rounded-left-side'
							>
								<HomeOutlined /> &nbsp; Dashboard
								<span className='sr-only'>(current)</span>
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/categories'
								activeClassName='active rounded-left-side'
							>
								<UnorderedListOutlined /> &nbsp; Categories
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/counselor'
								activeClassName='active rounded-left-side'
							>
								<UserOutlined /> &nbsp; Counselor
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/question'
								activeClassName='active rounded-left-side'
							>
								<QuestionCircleOutlined /> &nbsp; Question
								Section
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/answer'
								activeClassName='active rounded-left-side'
							>
								<CheckSquareOutlined /> &nbsp; Answer Section
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/coreteam'
								activeClassName='active rounded-left-side'
							>
								<TeamOutlined /> &nbsp; Core Team
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/scholarassists'
								activeClassName='active rounded-left-side'
							>
								<BookOutlined /> &nbsp; Scholarships
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								exact
								to='/admin/email'
								activeClassName='active rounded-left-side'
							>
								<MailOutlined /> &nbsp; Email
							</NavLink>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	)
}
export default AdminSideBar
