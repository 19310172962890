import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../node_modules/bootstrap/js/dist/util.js'
import './App.css'

import { Switch, Route, Redirect } from 'react-router-dom'
import { About } from '../components/About'
import { AskQuestion } from '../components/AskQuestion'
import { Category } from '../components/Category'
import { Counselor } from '../components/Counselor'
import { Home } from '../components/Home'
import { XomidhanAdmin } from '../components/XomidhanAdmin'
import { AppNavBar } from '../components/common/AppNavBar'
import { AppFooter } from '../components/common/Footer'
import { JoinXomidhan } from '../components/JoinXomidhan'
import { ScholarAssist } from '../components/ScholarAssist'
import { XomidhanTeam } from '../components/XomidhanTeam'
import { UpdateCounselor } from '../components/UpdateCounselor'
import { Search } from '../components/Search'

export default function App() {
	const path = window.location.pathname
	if (path.indexOf('/admin') < 0) {
		return (
			<>
				<div className='container-fluid'>
					<div className='row navbar-holder'>
						<div className='col col-lg-12 '>
							<AppNavBar />
						</div>
					</div>
					<div className='row '>
						<div className='col-lg-12'>
							<Switch>
								<Route exact path='/' component={Home} />
								<Route exact path='/about' component={About} />
								<Route
									exact
									path='/categories'
									component={Category}
								/>
								<Route
									exact
									path='/counselors'
									component={Counselor}
								/>
								<Route
									exact
									path='/ask'
									component={AskQuestion}
								/>
								<Route
									exact
									path='/join'
									component={JoinXomidhan}
								/>
								<Route
									exact
									path='/scholarassist'
									component={ScholarAssist}
								/>
								<Route
									exact
									path='/coreteam'
									component={XomidhanTeam}
								/>
								<Route
									exact
									path='/modification/profile'
									component={UpdateCounselor}
								/>
								<Route
									exact
									path='/search/query=:searchString'
									component={Search}
								/>
								<Redirect to='/' />
							</Switch>

							<div className='row'>
								<div className='col-lg-12'>
									<AppFooter />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	} else {
		return <XomidhanAdmin />
	}
}
