import { useState } from "react"
import * as updateCounselorActions from "./updateCounselor.actions"
import { JoinXomidhanForm } from "../JoinXomidhan/JoinXomidhanForm"
import { MyToast } from "../MyToast"
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap"

const UpdateCounselor = () => {
	const [email, setEmail] = useState("")
	const [emailEntered, setEmailEntered] = useState(false)
	const [otp, setOtp] = useState("")
	const [optVerified, setOptVerified] = useState(false)
	const [counselor, setCounselor] = useState({})
	const [loading, setLoading] = useState(false)
	const [toast, setToast] = useState({
		message: "",
		visible: false,
		timeOut: 3000,
		type: "success",
	})

	const emailSubmit = e => {
		e.preventDefault()
		updateCounselorActions.getOtp(
			{ setLoading, data: { email } },
			(res, err) => {
				if (!err) {
					setToast({
						...toast,
						visible: true,
						message: res.otpMessage,
						type: "success",
					})
					setEmailEntered(true)
				} else {
					setToast({
						...toast,
						visible: true,
						message: "Enter a Valid Email ID",
						type: "failure",
					})
				}
			}
		)
	}

	const otpSubmit = e => {
		e.preventDefault()
		updateCounselorActions.verifyOtp(
			{ setLoading, data: { email, otp } },
			(res, err) => {
				if (!err) {
					setCounselor(res)
					setToast({
						...toast,
						visible: true,
						message: "OTP Verified",
						type: "success",
					})
					setOptVerified(true)
				} else {
					setToast({
						...toast,
						visible: true,
						message: "Wrong OTP...Check and try again",
						type: "failure",
					})
				}
			}
		)
	}

	const hideToast = () => {
		setToast({ ...toast, visible: false, message: "" })
	}

	return (
		<Container fluid className="py-5 bg-light">
			<MyToast
				message={toast.message}
				visible={toast.visible}
				type={toast.type}
				timeOut={toast.timeOut}
				hideToast={hideToast}
			/>
			{emailEntered ? (
				<>
					<Form method="POST" onSubmit={otpSubmit} className="px-4">
						<Form.Group as={Row}>
							<Form.Label column sm="2">
								{optVerified ? "OTP: " : "Enter OTP:"}
							</Form.Label>
							<Col sm="10">
								<Form.Control
									type="text"
									value={otp}
									onChange={e => setOtp(e.target.value)}
									disabled={optVerified ? true : false}
								/>
							</Col>
						</Form.Group>
						{optVerified || (
							<Row className="justify-content-center">
								{loading ? (
									<Button type="button" name="submit" disabled>
										<Spinner animation="border" variant="success" size="sm" />
									</Button>
								) : (
									<Button type="submit" name="Submit">
										Submit
									</Button>
								)}
							</Row>
						)}
					</Form>
					{optVerified && counselor && (
						<JoinXomidhanForm
							counselor={counselor}
							otp={otp}
							setEmailEntered={setEmailEntered}
						/>
					)}
				</>
			) : (
				<Form method="POST" onSubmit={emailSubmit} className="px-4">
					<Form.Group as={Row}>
						<Form.Label column sm="3">
							Enter your Email ID:
						</Form.Label>
						<Col sm="9">
							<Form.Control
								type="email"
								placeholder="name@example.com"
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
						</Col>
					</Form.Group>
					<Row className="justify-content-center">
						{loading ? (
							<Button type="button" name="Submit" disabled>
								<Spinner animation="border" variant="success" size="sm" />
							</Button>
						) : (
							<Button type="submit" name="Submit">
								Submit
							</Button>
						)}
					</Row>
				</Form>
			)}
		</Container>
	)
}

export default UpdateCounselor
