import { React, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { TeamMember } from '../XomidhanTeam/TeamMember'
import { Accordion, Card } from 'react-bootstrap'
import Skeleton from '@material-ui/lab/Skeleton'
import { getCounselors } from './counselor.actions'

const useQuery = () => {
	const params = new URLSearchParams(useLocation().search)
	return params.get('cat')
}

function Counselor() {
	let query = useQuery()
	const [counselors, setCounselors] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (query) {
			getCounselors(
				{
					setLoading,
					category: query,
				},
				(res, err) => {
					if (!err) {
						setCounselors(res._embedded.counsellors)
					} else {
						console.log('something went wrong', res)
					}
				}
			)
		}
	}, [query])

	const renderCounselorsAccordion = () => {
		return (
			<Accordion>
				{counselors.length ? (
					<>
						{counselors.map((counselor, i) => {
							return (
								<Card
									key={`counselor_${i}`}
									className='cursor-pointer'
								>
									<Accordion.Toggle
										as={Card.Header}
										eventKey={`${i}`}
									>
										{counselor.name}
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={`${i}`}>
										<Card.Body>
											<TeamMember
												image={
													counselor.hasPhoto &&
													`${counselor._links.self.href}/pic`
												}
												altText={counselor.altText}
												name={counselor.name}
												profession={
													counselor.currentProfession
												}
												education={
													counselor.educationalQualification
												}
												desc={counselor.aboutMe}
												inspiration={
													counselor.myInspiration
												}
											/>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							)
						})}
					</>
				) : (
					<div>No counselors found</div>
				)}
			</Accordion>
		)
	}
	return (
		<>
			<div
				className='container-fluid bg-light'
				style={{ minHeight: '60vh' }}
			>
				<div className='row'>
					<div className='col-sm-9'>
						<div
							className='titleBox'
							style={{ paddingTop: '40px', paddingLeft: '40px' }}
						>
							<h2>Counselors </h2>
						</div>
					</div>
					<div className='col-sm-3 text-right pr-4 pt-3'>
						<NavLink
							exact
							to='/categories'
							className='back-btn'
							style={{
								paddingTop: '40px',
								paddingLeft: '40px',
								display: 'block',
							}}
						>
							<i
								className='fa fa-chevron-left'
								aria-hidden='true'
							></i>{' '}
							Back
						</NavLink>
					</div>
				</div>

				<div
					className='row'
					style={{
						paddingTop: 40,
						paddingLeft: 40,
						paddingBottom: 40,
					}}
				>
					<div className='col-12 m-auto'>
						<div>
							{loading ? (
								<div>
									<Skeleton
										variant='rect'
										width={450}
										height={15}
									/>
									<br />
									<Skeleton
										variant='rect'
										width={450}
										height={15}
									/>
									<br />
									<Skeleton
										variant='rect'
										width={450}
										height={15}
									/>
								</div>
							) : (
								renderCounselorsAccordion()
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Counselor
