import { useState, useEffect, useRef, useCallback } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { TextError } from '../JoinXomidhan/JoinXomidhanForm'
import { MyToast } from '../MyToast'
import Quote from '../common/bits/Quote/Quote'
import { addQuestion } from './askQuestion.actions'
import styles from './askQuestion.module.css'
import {
	useGoogleReCaptcha,
	GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
import {APP_ENVIRONMENT} from '../../utils/apiCall'
import { recaptcha_site_key, recaptcha_site_key_prod } from '../../utils/constant'

const validationSchema = Yup.object().shape({
	name: Yup.string().required().min(3).label('Name'),
	email: Yup.string().email().required().label('E-mail'),
	phone: Yup.number('Enter valid number')
		.typeError('Not a valid number')
		.nullable()
		.notRequired('Must be 10 digit long')
		.max(9999999999, 'Number can not contain more than 10 digits')
		.label('Phone'),
	currentEducation: Yup.string().required().min(3).label('Current Education'),
	question: Yup.string().required().min(20).label('Question to Counselor'),
	marksIn10and12th: Yup.string()
		.required()
		.label('Marks secured in 10th, 12th and Graduations examinations'),
	aim: Yup.string()
		.required()
		.label('I want to become a (Please mention your aim)'),
	educationalBackground: Yup.string()
		.required()
		.label('Brief description of your educational background'),
})

function AskQuestionForm({
	question,
	updateQuestion = () => {},
	setShowEditQuestionModal = () => {},
}) {
	const captchaRef = useRef()
	const { executeRecaptcha } = useGoogleReCaptcha()
	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		phone: '',
		currentEducation: '',
		question: '',
		marksIn10and12th: '',
		aim: '',
		educationalBackground: '',
	})
	const [toast, setToast] = useState({
		message: '',
		visible: false,
		timeOut: 5000,
		type: 'success',
	})

	useEffect(() => {
		if (question) {
			let temp = { ...question }
			temp.phone = temp.phone !== null ? temp.phone.number : ''
			delete temp._links
			delete temp.createdAt
			delete temp.modifiedAt
			delete temp.isDeleted
			delete temp.mailSentCount
			setInitialValues(temp)
		}
	}, [])

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log('recaptcha not yet available')
			return ''
		}
		const token = await executeRecaptcha('question')
		return token
	}, [executeRecaptcha])

	useEffect(() => {
		handleReCaptchaVerify()
			.then((token) => {
				captchaRef.current = token
			})
			.catch((err) => console.error('something went wrong', err))
	}, [handleReCaptchaVerify])

	const submitForm = (values, { resetForm }) => {
		let data = { ...values }
		data.phone =
			values.phone && values.phone.length > 0
				? { number: data.phone }
				: null
		console.log('Before upload: ' + data)
		if (question) {
			updateQuestion(data, question._links.self.href, 'PATCH')
			setShowEditQuestionModal(false)
		} else {
			addQuestion(
				{ data, recaptchaToken: captchaRef.current },
				(res, err) => {
					if (!err) {
						setToast({
							...toast,
							message:
								'Question submitted. Answer will be sent to your email',
							visible: true,
							type: 'success',
						})
						resetForm({
							values: {
								name: '',
								email: '',
								phone: '',
								currentEducation: '',
								question: '',
								marksIn10and12th: '',
								aim: '',
								educationalBackground: '',
							},
						})
						window.location.reload()
					} else {
						let errorMessage
						if (res?.errors) {
							errorMessage = Object.keys(res.errors || {})
								.map((k) => res.errors[k])
								.join('<br/>')
						}
						setToast({
							...toast,
							message: errorMessage
								? errorMessage
								: 'Something went wrong... Try again...',
							visible: true,
							type: 'failure',
						})
					}
				}
			)
		}
	}

	return (
		<>
			<MyToast
				message={toast.message}
				visible={toast.visible}
				type={toast.type}
				timeOut={toast.timeOut}
				hideToast={() =>
					setToast({ ...toast, visible: false, message: '' })
				}
			/>
			<div className='container-fluid'>
				<div className='row'>
					{question !== undefined || (
						<div
							className='col-lg-6 col-sm-12'
							style={{
								backgroundColor: '#fafafa',
							}}
						>
							<div
								style={{
									height: '100%',
									paddingBottom: '20px',
								}}
							>
								<Quote />
							</div>
						</div>
					)}
					<div
						className={
							question
								? 'col-12 bg-light'
								: 'col-lg-6 col-sm-12 bg-light'
						}
						style={{
							backgroundColor: '#fff',
							padding: '50px 30px',
						}}
					>
						{question !== undefined || (
							<div
								className={styles.titleHolder}
								style={{ textAlign: 'center' }}
							>
								<div className='titleBox'>
									<h3>Ask a Question</h3>
								</div>
							</div>
						)}
						<Formik
							enableReinitialize
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submitForm}
						>
							{/*  handleChange, handleSubmit, errors, values, touched  */}
							{({ handleSubmit }) => (
								<div
									className='container-fluid '
									style={{ alignItems: 'center' }}
								>
									<Form
										onSubmit={handleSubmit}
										className='g-6 justify-content-center align-items-center'
									>
										{question === undefined && (
											<>
												<div className='row'>
													<div className='col-12 form-group p-2 '>
														<label htmlFor='name'>
															Name
														</label>
														<Field
															width='100%'
															type='text'
															id='name'
															name='name'
															placeholder='Name'
															className='form-control'
														/>
														<ErrorMessage
															name='name'
															component={
																TextError
															}
														/>
													</div>
												</div>
												<div className='row'>
													<div className='col-12 form-group p-2'>
														<label htmlFor='email'>
															Email
														</label>
														<Field
															type='email'
															className='form-control'
															id='email'
															name='email'
															placeholder='name@example.com'
														/>
														<ErrorMessage
															name='email'
															component={
																TextError
															}
														/>
													</div>
												</div>
												<div className='row'>
													<div className='col-12 form-group p-2'>
														<label htmlFor='phone'>
															Phone
														</label>
														<Field
															className='form-control'
															name='phone'
															placeholder='Phone Number...'
														/>
														<ErrorMessage
															name='phone'
															component={
																TextError
															}
														/>
													</div>
												</div>
											</>
										)}
										<div className='row'>
											<div className='col-12 form-group p-2'>
												<label htmlFor='currentEducation'>
													Current Education
												</label>
												<Field
													as='textarea'
													type='text'
													className='form-control'
													name='currentEducation'
													placeholder='Current Education'
													rows={5}
												/>
												<ErrorMessage
													name='currentEducation'
													component={TextError}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 form-group  p-2'>
												<label htmlFor='question'>
													Question
												</label>
												<Field
													as='textarea'
													className='form-control'
													name='question'
													placeholder='Question to Counselor'
													rows={5}
												/>
												<ErrorMessage
													name='question'
													component={TextError}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 form-group p-2'>
												<label htmlFor='marksIn10and12th'>
													Marks
												</label>
												<Field
													as='textarea'
													rows={5}
													type='text'
													className='form-control'
													name='marksIn10and12th'
													placeholder='Marks secured in 10th, 12th and Graduations examinations'
												/>
												<ErrorMessage
													name='marksIn10and12th'
													component={TextError}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 form-group  p-2'>
												<label htmlFor='aim'>Aim</label>
												<Field
													as='textarea'
													rows={5}
													className='form-control'
													name='aim'
													maxLength='2500'
													placeholder='I want to become a (Please mention your aim)'
												/>
												<ErrorMessage
													name='aim'
													component={TextError}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 form-group  p-2'>
												<label htmlFor='educationalBackground'>
													Educational Background
												</label>
												<Field
													as='textarea'
													rows={5}
													className='form-control'
													name='educationalBackground'
													maxLength='2500'
													placeholder='Brief description of your educational background'
												/>
												<ErrorMessage
													name='educationalBackground'
													component={TextError}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 p-2'>
												<button
													type='submit'
													className='btn'
													style={{
														backgroundColor:
															'#1687a7',
														color: '#fff',
													}}
												>
													{question
														? 'Update'
														: 'Submit Question'}
												</button>
											</div>
										</div>
									</Form>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	)
}

const AskQuestion = (props) => {
    let site_key=recaptcha_site_key;
    if(APP_ENVIRONMENT==='prod'){
        site_key=recaptcha_site_key_prod
    }
	return (
		<GoogleReCaptchaProvider reCaptchaKey={site_key}>
			<AskQuestionForm {...props} />
		</GoogleReCaptchaProvider>
	)
}

export default AskQuestion
