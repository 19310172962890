export const getCounselors = (params, callback) => {
	const request = {
		method: 'GET',
		url: `/counsellors/search/findByApproved?approved=${params.status}&page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const getSearchedCounselors = (params, callback) => {
	const request = {
		method: 'GET',
		url: `/counsellors/search/findByName?name=${params.name}&approved=${params.status}&page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const getCategories = (params, callback) => {
	const request = {
		method: 'GET',
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}

export const patchCounselors = (params, callback) => {
	const request = {
		method: 'PATCH',
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const removeCounselor = (params, callback) => {
	const request = {
		method: 'DELETE',
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}
