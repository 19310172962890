export const getScholarShips = (params, callback) => {
	const request = {
		method: 'GET',
		url: `/scholarships/search/findByStatus?status=${params.status}&page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const addScholarShip = (params, callback) => {
	const request = {
		method: 'POST',
		url: '/scholarships',
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const editScholarship = (params, callback) => {
	const request = {
		method: 'PATCH',
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}

export const removeScholarship = (params, callback) => {
	const request = {
		method: 'DELETE',
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}
