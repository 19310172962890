export const getQuestions = (params, callback) => {
	const request = {
		method: 'GET',
		url: `/questions?answered=true&type=nonAT&page=${params.page}&size=${params.size}`,
	}
	params.apiCallInterpreter(request, callback)
}

export const getAnswer = (params, callback) => {
	const request = {
		method: 'GET',
		url: params.url,
	}
	params.apiCallInterpreter(request, callback)
}

export const updateAnswer = (params, callback) => {
	const request = {
		method: 'PATCH',
		url: params.url,
		data: params.data,
	}
	params.apiCallInterpreter(request, callback)
}
